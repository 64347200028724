import React, { useState } from 'react';
import ServicesCreate from '../components/Services/ServicesCreate';
import ServicesTable from '../components/Services/ServicesTable';

function Services() {
  const [collapsed, setCollapsed] = useState(true);

  return (
    <>

      <div
        className={`bg-[#598DF9] text-orientation-mixed writing-mode-vertical text-gray-100 text-xl font-semibold indent-5 pr-0.5 pl-1.5 pt-10`} //text-orientation-mixed writing-mode-vertical are custom css utilities created in index.css to enable vertical text
      >
        Edit Service
      </div>

      <div
        className= {`bg-[#fbfbfd] ${collapsed ? "w-full p-5"  : "w-1 overflow-hidden opacity-0"} duration-700 whitespace-nowrap`}
      >
        <h1 className="text-xl font-bold pb-5">Edit Services Form</h1>
        <ServicesTable/>
      </div>

      <div
        onClick={() => setCollapsed(!collapsed)}
        className="cursor-pointer bg-gray-600 text-orientation-mixed writing-mode-vertical text-gray-100 text-xl font-semibold indent-5 pr-0.5 pl-1.5 pt-10"
      >
        Create New Service
      </div>

      <div
        className={` bg-[#fbfbfd] ${collapsed ? "w-0 overflow-hidden opacity-0" : 'w-full'} duration-500 whitespace-nowrap`}
      >
        <h1 className="text-xl font-bold p-5">New Service Creation Form</h1>

        <ServicesCreate />
      </div>
    </>
  );
}

export default Services;
