import React from 'react';
import { useField } from 'formik';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import enGB from 'date-fns/locale/en-GB';
import { parseISO } from 'date-fns';

const CustomDateField = ({ name, label, error, helperText, ...props }) => {
  const [field, meta, helpers] = useField(name);
  const locale = enGB;

  const handleDateChange = (date) => {
    if (typeof date === 'string') {
      const parsedDate = parseISO(date);
      helpers.setValue(parsedDate);
    } else {
      helpers.setValue(date);
    }
  };

  return (
    <div className="pb-4">
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
        <DatePicker
          {...field}
          {...props}
          name={name}
          label={label}
          value={parseISO(field.value) || null}
          onChange={handleDateChange}
          sx={{
            width: '100%',
          }}
          slotProps={{
            textField: {
              variant: 'outlined',
              error: Boolean(meta.touched && meta.error), //error handling
              helperText: meta.touched && meta.error ? meta.error : helperText,
            },
          }} //error handling
        />
      </LocalizationProvider>
    </div>
  );
};

export default CustomDateField;
