import { forwardRef } from 'react';
import ADCA_Logo from '../../assets/ADCALogoSmallBW.png';

const ComponentToPrint = forwardRef((props, ref) => {
  const memberIds = props.formValues?.member_id;
  const fullNames = memberIds?.map((member) => member.FullName) || [];
  const eventDate = props.formValues?.event_date;
  const eventName = props.formValues?.event_id;
  const staffIds = props.formValues?.staff_id;
  const staffNames = staffIds?.map((staff) => staff.FullName) || [];

  const totalRows = fullNames.length + 3;

  return (
    <>
      <div className="pl-100 pr-10">
        <div ref={ref}>
          <div className="text-3xl pl-5 pr-5 pb-10 pt-5 text-center"></div>

          <table>
            <tbody>
              <tr>
                <td>
                  <img className="pl-5 logo-image" src={ADCA_Logo} alt="Logo" />
                </td>
                <td>
                  <div className="text-4xl pl-10 pr-5 pb-10 pt-10 text-center">
                    ADCA Event Register & Cash Sheet
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <table>
            <tbody>
              <tr>
                <td className="font-bold text-lg pl-5 pr-5 pb-2">Name:</td>
                <td>{eventName}</td>
              </tr>
              <tr>
                <td className="font-bold text-lg pl-5 pr-5 pb-2">Date:</td>
                <td>{eventDate}</td>
              </tr>
              <tr>
                <td className="font-bold text-lg pl-5 pr-5 pb-10">Staff:</td>
                <td className="pb-10">{staffNames?.join(', ')}</td>
              </tr>
            </tbody>
          </table>

          <table className="w-full">
            <tbody>
              <tr className="bg-gray-200">
                <th className="border border-black p-2 pl-1 pr-1 w-1/8">NO.</th>
                <th className="border border-black pr-1 w-4/8">
                  MEMBER NAME
                </th>
                <th className="border  border-black p-2 pl-1 pr-1 w-1/6">
                  FOOTBALL CARD (£)
                </th>
                <th className="border border-black p-2 pl-1 pr-1 w-1/6">
                  CLUB MONEY (£)
                </th>
                <th className="border border-black p-2 pl-1 pr-1 w-1/6">
                  OTHER (£)
                </th>
                <th className="border border-black p-2 pl-1 pr-1 w-1/10">
                  BUS REGISTER
                </th>
              </tr>
              {[...Array(totalRows)].map((_, index) => {
                if (index === totalRows - 1) {
                  return (
                    <tr key={index} className="h-3">
                      <td className="border border-black p-1 pl-1 pr-1 text-center font-bold">
                        TOTALS:
                      </td>
                      <td className="border border-black p-1 pl-6 pr-1"></td>
                      <td className="border border-black p-1 pl-1 pr-1"></td>
                      <td className="border border-black p-1 pl-1 pr-1"></td>
                      <td className="border border-black p-1 pl-1 pr-1"></td>
                    </tr>
                  );
                } else {
                  return (
                    <tr key={index} className="h-3">
                      <td className="border border-black p-1 pl-1 pr-1 text-center">
                        {`${index + 1}.`}
                      </td>
                      <td className="border border-black p-1 pl-3 pr-1">
                        {fullNames[index] || ''}
                      </td>
                      <td className="border border-black p-1 pl-1 pr-1"></td>
                      <td className="border border-black p-1 pl-1 pr-1"></td>
                      <td className="border border-black p-1 pl-1 pr-1"></td>
                      <td className="border border-black p-1 pl-1 pr-1"></td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
});

export default ComponentToPrint;
