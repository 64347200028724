import React, { useState, memo } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup'; //yup does form validation
import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import { useRecoilState, useRecoilValue } from 'recoil';
import { memberButtonClickedState } from '../Atoms/atoms';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { userNameState } from '../Atoms/atoms';
import CustomSelectField from '../CustomComponents/CustomSelectField';
import CustomTextField from '../CustomComponents/CustomTextField';
import CustomDateField from '../CustomComponents/CustomDateField';
import CustomMultiLineTextField from '../CustomComponents/CustomMultiLineTextField';
import UploadWidget from '../CustomComponents/UploadWidget';

//react-query useMutation code
const useMembersCreateMutation = () => {
  return useMutation(async (formPayload) => {
    // Second axios post just makes a post request with no data sent, which triggers SQL in the route which populates the member_comment table;
    // by looking up data from the member table, in particular the member-id, when this form is saved
    // eslint-disable-next-line no-sequences
    return (
      await axios.post(process.env.REACT_APP_BE_URL + '/members', formPayload),
      await axios.post(
        process.env.REACT_APP_BE_URL + '/memberscomments/initialmembercomment'
      )
    );
  });
};

//Main function - creates Formik form
const MembersCreate = memo(() => {
  const { mutate } = useMembersCreateMutation();

  //Gets logged in username from auth.js
  const userName = useRecoilValue(userNameState);

  //Tracks how many times form has been submitted, so initialValues can be set explicitly to 'undefined' in first instance
  //then '' if the form has already been submitted.
  //This enables the formik resetForm function to set the content of the field to null after form submission
  const [timesFormSubmitted, setTimesFormSubmitted] = useState(0);

  //Formik initial values
  const initialValues = {
    //Contact Info
    member_type: undefined,
    forename: '',
    surname: '',
    known_as: '',
    date_of_birth: timesFormSubmitted > 0 ? '' : undefined,
    gender: '',
    ethnicity: '',
    address_1: '',
    address_2: '',
    address_3: '',
    town: '',
    postcode: '',
    directions: '',
    phone_number: '',
    email_address: '',
    next_of_kin_name: '',
    next_of_kin_relationship: '',
    next_of_kin_phone: '',
    next_of_kin_local: '',
    emergency_contact_2_name: '',
    emergency_contact_2_relationship: '',
    emergency_contact_2_phone: '',

    //Medical Info
    doctor_name: '',
    surgery_name: '',
    doctor_phone: '',
    medications_taken: '',
    impaired_hearing: '',
    impaired_vision: '',
    impaired_mobility: '',
    fall_risk: '',
    dementia: '',
    impaired_communication: '',
    wander_risk: '',
    diabetes: '',
    food_allergies: '',
    food_allergy_list: '',
    catheter: '',
    medical_equipment: '',
    other_medical_issues: '',
    gdpr_form_signed: '',
    gdpr_form_link: '',

    //Other Info
    interests: '',
    topics_to_avoid: '',
    blue_badge_holder: '',
    other_info: '',

    //Carer Specific Fields
    cared_for_medical_conditions: '',

    //Hidden Fields
    last_updated_by: userName.user.displayName,
    is_deceased: 'No',
  };

  // Yup field validation
  const validationSchema = Yup.object().shape({
    member_type: Yup.string().required('*Member type is required'),

    forename: Yup.string()
      .required('*Forename is required')
      .max(35, 'Forename can be a maximum of 35 characters'),

    surname: Yup.string()
      .required('*Surname is required')
      .max(35, 'Surname can be a maximum of 35 characters'),

    known_as: Yup.string().max(
      35,
      'Likes To Be Known As can be a maximum of 35 characters'
    ),

    //Custom code to replace yup's standard (horrible) error message if the date entered is invalid, e.g. 10/05/5
    date_of_birth: Yup.string().when(['member_type'], {
      is: (member_type) =>
        member_type?.includes('Carer') ||
        member_type?.includes('Standard Member'),
      then: Yup.string()
        .nullable() // Allow the field to be nullable
        .required('*Date of birth is required')
        .test('valid-date', '*Date of birth must be a valid date', (value) => {
          return value !== 'Invalid Date';
        }),
    }),

    gender: Yup.string().when(['member_type'], {
      is: (member_type) => member_type?.includes('Standard Member'),
      then: Yup.string().required('*Gender is required'),
    }),

    ethnicity: Yup.string().when(['member_type'], {
      is: (member_type) => member_type?.includes('Standard Member'),
      then: Yup.string().required('*Ethnicity is required'),
    }),

    address_1: Yup.string()
      .when(['member_type'], {
        is: (member_type) =>
          member_type?.includes('Standard Member') ||
          member_type?.includes('Service User'),
        then: Yup.string().required('*Address Line 1 is required'),
      })
      .max(35, 'Address Line 1 can be a maximum of 35 characters'),

    address_2: Yup.string().max(
      35,
      'Address Line 2 can be a maximum of 35 characters'
    ),

    address_3: Yup.string().max(
      35,
      'Address Line 3 can be a maximum of 35 characters'
    ),

    town: Yup.string().max(35, 'Town can be a maximum of 35 characters'),

    postcode: Yup.string()
      .when(['member_type'], {
        is: (member_type) =>
          member_type?.includes('Standard Member') ||
          member_type?.includes('Service User'),
        then: Yup.string().required('*Postcode is required'),
      })
      .max(12, 'Postcode can be a maximum of 12 characters'),

    directions: Yup.string(),

    phone_number: Yup.string()
      .required('* A phone number is required')
      .max(12, 'Phone number can be a maximum of 12 characters'),

    email_address: Yup.string()
      .email('*Invalid email address format')
      .max(255, 'Email address can be a maximum of 255 characters'),

    next_of_kin_name: Yup.string()
      .when(['member_type'], {
        is: (member_type) => member_type?.includes('Standard Member'),
        then: Yup.string().required('* Next Of Kin Name is required'),
      })
      .max(70, 'Next of Kin Name can be a maximum of 70 characters'),

    next_of_kin_relationship: Yup.string().when(['member_type'], {
      is: (member_type) => member_type?.includes('Standard Member'),
      then: Yup.string().required('*Next of Kin Relationship is required'),
    }),

    next_of_kin_phone: Yup.string()
      .when(['member_type'], {
        is: (member_type) => member_type?.includes('Standard Member'),
        then: Yup.string().required('*Next of Kin Phone Number is required'),
      })
      .max(12, 'Next of Kin Phone can be a maximum of 12 characters'),

    next_of_kin_local: Yup.string().when(['member_type'], {
      is: (member_type) => member_type?.includes('Standard Member'),
      then: Yup.string().required(
        '*Whether next of kin lives locally is required'
      ),
    }),

    emergency_contact_2_name: Yup.string().max(
      70,
      'Emergency Contact Name can be a maximum of 70 characters'
    ),

    emergency_contact_2_relationship: Yup.string(),

    emergency_contact_2_phone: Yup.string().max(
      12,
      'Emergency Contact Phone can be a maximum of 12 characters'
    ),

    doctor_name: Yup.string().when(['member_type'], {
      is: (member_type) => member_type?.includes('Standard Member'),
      then: Yup.string()
        .required("*Doctor's name is required")
        .max(35, 'Doctor can be a maximum of 35 characters'),
    }),

    surgery_name: Yup.string().when(['member_type'], {
      is: (member_type) => member_type?.includes('Standard Member'),
      then: Yup.string()
        .required('*Surgery Name is required')
        .max(35, 'Surgery Name can be a maximum of 35 characters'),
    }),

    doctor_phone: Yup.string().when(['member_type'], {
      is: (member_type) => member_type?.includes('Standard Member'),
      then: Yup.string().max(
        12,
        "Doctor's Phone Number can be a maximum of 12 characters"
      ),
    }),

    medications_taken: Yup.string().when(['member_type'], {
      is: (member_type) => member_type?.includes('Standard Member'),
      then: Yup.string().required(
        "*Details of medications are required. 'None' is a valid entry"
      ),
    }),

    impaired_hearing: Yup.string().when(['member_type'], {
      is: (member_type) => member_type?.includes('Standard Member'),
      then: Yup.string().required(
        '*Hearing impairment information is required'
      ),
    }),

    impaired_vision: Yup.string().when(['member_type'], {
      is: (member_type) => member_type?.includes('Standard Member'),
      then: Yup.string().required('*Vision impairment information is required'),
    }),

    impaired_mobility: Yup.string().when(['member_type'], {
      is: (member_type) => member_type?.includes('Standard Member'),
      then: Yup.string().required(
        '*Mobility impairment information is required'
      ),
    }),

    fall_risk: Yup.string().when(['member_type'], {
      is: (member_type) => member_type?.includes('Standard Member'),
      then: Yup.string().required('*Fall risk information is required'),
    }),

    dementia: Yup.string().when(['member_type'], {
      is: (member_type) => member_type?.includes('Standard Member'),
      then: Yup.string().required('*Dementia or similar issues information is required'),
    }),

    impaired_communication: Yup.string(),

    wander_risk: Yup.string(),

    diabetes: Yup.string().when(['member_type'], {
      is: (member_type) => member_type?.includes('Standard Member'),
      then: Yup.string().required('*Diabetes information is required'),
    }),

    food_allergies: Yup.string().when(['member_type'], {
      is: (member_type) => member_type?.includes('Standard Member'),
      then: Yup.string().required('*Food allergy information is required'),
    }),

    food_allergy_list: Yup.string().when(['food_allergies'], {
      is: (food_allergy_list) => food_allergy_list === 'Yes',
      then: Yup.string().required(
        '*A list of food allergies is needed if the Food Allergies field is set to Yes'
      ),
    }),

    catheter: Yup.string().when(['member_type'], {
      is: (member_type) =>
        member_type !== 'Carer' && member_type !== 'Service User',
      then: Yup.string().required('*Catheter information is required'),
    }),

    medical_equipment: Yup.string(),

    other_medical_issues: Yup.string(),

    interests: Yup.string(),

    topics_to_avoid: Yup.string(),

    blue_badge_holder: Yup.string().max(
      35,
      'Blue Badge Holder can be a maximum of 35 characters'
    ),

    other_info: Yup.string(),

    gdpr_form_signed: Yup.string().required(
      '*GDPR form signed is a required field'
    ),

    gdpr_form_link: Yup.string().when(['gdpr_form_signed'], {
      is: (gdpr_form_signed) => gdpr_form_signed === 'Yes',
      then: Yup.string().required(
        '*A link to the signed GDPR form is required'
      ),
    }),

    cared_for_medical_conditions: Yup.string().when(['member_type'], {
      is: (member_type) => member_type?.includes('Carer'),
      then: Yup.string().required(
        '*Cared for medical condition details are required'
      ),
    }),
  });

  // Recoil global state to trigger data table refresh after member edit button is clicked
  const [buttonisClicked, setButtonIsClicked] = useRecoilState(
    memberButtonClickedState
  );

  // State for MUI snackbar popup open status
  const [open, setOpen] = useState(false);

  //State for MUI snackbar popup message
  const [message, setMessage] = useState('');

  //State for MUI snackbar popup severity
  const [severity, setSeverity] = useState();

  // Variable for property to close MUI snackbar popup
  const handleClose = (reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <div className="createMemberPage px-5 relative">
      <Formik
        enableReinitialize={false}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, formik) => {
          mutate(values, {
            onSuccess: () => {
              setOpen(true);
              setMessage('New member created!');
              setSeverity('success');
              setButtonIsClicked(buttonisClicked + 1); //updates Recoil global state, to trigger data-table refetch of data
              setTimesFormSubmitted(timesFormSubmitted + 1); //sets state needed for controlling reset of the date_of_birth field
              formik.resetForm();
            },
            onError: (response) => {
              setOpen(true);
              setMessage('Error, new member not created!');
              setSeverity('error');
            },
          });
        }}
      >
        <Form className="formContainer">
          <h1 className="pb-3 text-xl font-semibold">Contact Information</h1>

          <div>
            <CustomSelectField
              name="member_type"
              label="Member Type"
              options={[
                { label: 'Standard Member', value: 'Standard Member' },
                { label: 'Carer', value: 'Carer' },
                { label: 'Service User', value: 'Service User' },
                {
                  label: 'Standard Member & Carer',
                  value: 'Standard Member & Carer',
                },
                {
                  label: 'Standard Member & Service User',
                  value: 'Standard Member & Service User',
                },
                {
                  label: 'Standard Member & Carer & Service User',
                  value: 'Standard Member & Carer & Service User',
                },
                {
                  label: 'Carer & Service User',
                  value: 'Carer & Service User',
                },
              ]}
            />
          </div>

          <div>
            <CustomTextField name="forename" label="Forename" />
          </div>

          <div>
            <CustomTextField name="surname" label="Surname" />
          </div>

          <div>
            <CustomTextField name="known_as" label="Likes to be known as" />
          </div>

          <div>
            <CustomDateField name="date_of_birth" label="Date Of Birth" />
          </div>

          <div>
            <CustomSelectField
              name="gender"
              label="Gender"
              options={[
                { label: 'Male', value: 'Male' },
                { label: 'Female', value: 'Female' },
                { label: 'Declined', value: 'Declined' },
              ]}
            />
          </div>

          <div>
            <CustomSelectField
              name="ethnicity"
              label="Ethnicity"
              options={[
                { label: 'White', value: 'White' },
                { label: 'Asian/Asian/British', value: 'Asian/Asian/British' },
                { label: 'Black/Black British', value: 'Black/Black British' },
                { label: 'Mixed Ethnicity', value: 'Mixed Ethnicity' },
                { label: 'Other', value: 'Other' },
                { label: 'Declined', value: 'Declined' },
              ]}
            />
          </div>

          <div>
            <CustomTextField name="address_1" label="Address Line 1" />
          </div>

          <div>
            <CustomTextField name="address_2" label="Address Line 2" />
          </div>

          <div>
            <CustomTextField name="address_3" label="Address Line 3" />
          </div>

          <div>
            <CustomTextField name="town" label="Town" />
          </div>

          <div>
            <CustomTextField name="postcode" label="Postcode" />
          </div>

          <div>
            <CustomTextField name="directions" label="Directions to home" />
          </div>

          <div>
            <CustomTextField name="phone_number" label="Phone number" />
          </div>

          <div>
            <CustomTextField name="email_address" label="Email Address" />
          </div>

          <div>
            <CustomTextField name="next_of_kin_name" label="Next of kin name" />
          </div>

          <div>
            <CustomSelectField
              name="next_of_kin_relationship"
              label="Next of kin relationship"
              options={[
                { label: 'Spouse', value: 'Spouse' },
                { label: 'Partner', value: 'Partner' },
                { label: 'Relative', value: 'Relative' },
                { label: 'Carer', value: 'Carer' },
                { label: 'Other', value: 'Other' },
              ]}
            />
          </div>

          <div>
            <CustomTextField
              name="next_of_kin_phone"
              label="Next of kin phone"
            />
          </div>

          <div>
            <CustomSelectField
              name="next_of_kin_local"
              label="Next of kin local"
              options={[
                { label: 'Yes', value: 'Yes' },
                { label: 'No', value: 'No' },
              ]}
            />
          </div>

          <div>
            <CustomTextField
              name="emergency_contact_2_name"
              label="2nd emergency contact name"
            />
          </div>

          <div>
            <CustomSelectField
              name="emergency_contact_2_relationship"
              label="2nd emergency contact relationship"
              options={[
                { label: 'Spouse', value: 'Spouse' },
                { label: 'Partner', value: 'Partner' },
                { label: 'Relative', value: 'Relative' },
                { label: 'Carer', value: 'Carer' },
                { label: 'Other', value: 'Other' },
              ]}
            />
          </div>

          <div>
            <CustomTextField
              name="emergency_contact_2_phone"
              label="2nd emergency contact phone number"
            />
          </div>

          <h1 className="pb-3 pt-5 text-xl font-semibold">
            Medical Information
          </h1>

          <div>
            <CustomTextField name="doctor_name" label="Doctor's name" />
          </div>

          <div>
            <CustomTextField
              name="surgery_name"
              label="Doctor's surgery name"
            />
          </div>

          <div>
            <CustomTextField
              name="doctor_phone"
              label="Doctor's surgery phone number"
            />
          </div>

          <div>
            <CustomMultiLineTextField
              name="medications_taken"
              label="Details of medications taken"
              rows="2"
            />
          </div>

          <div>
            <CustomSelectField
              name="impaired_hearing"
              label="Impaired hearing/hearing aid"
              options={[
                { label: 'Yes', value: 'Yes' },
                { label: 'No', value: 'No' },
              ]}
            />
          </div>

          <div>
            <CustomSelectField
              name="impaired_vision"
              label="Impaired vision"
              options={[
                { label: 'Yes', value: 'Yes' },
                { label: 'No', value: 'No' },
              ]}
            />
          </div>

          <div>
            <CustomSelectField
              name="impaired_mobility"
              label="Impaired mobility"
              options={[
                { label: 'Yes', value: 'Yes' },
                { label: 'No', value: 'No' },
              ]}
            />
          </div>

          <div>
            <CustomSelectField
              name="fall_risk"
              label="Fall risk"
              options={[
                { label: 'Yes', value: 'Yes' },
                { label: 'No', value: 'No' },
              ]}
            />
          </div>

          <div>
            <CustomSelectField
              name="dementia"
              label="Dementia or similar issues"
              options={[
                { label: 'Yes', value: 'Yes' },
                { label: 'No', value: 'No' },
              ]}
            />
          </div>

          <div>
            <CustomSelectField
              name="impaired_communication"
              label="Impaired communication"
              options={[
                { label: 'Yes', value: 'Yes' },
                { label: 'No', value: 'No' },
              ]}
            />
          </div>

          <div>
            <CustomSelectField
              name="wander_risk"
              label="Do they tend to wander"
              options={[
                { label: 'Yes', value: 'Yes' },
                { label: 'No', value: 'No' },
              ]}
            />
          </div>

          <div>
            <CustomSelectField
              name="diabetes"
              label="Diabetes"
              options={[
                { label: 'Yes', value: 'Yes' },
                { label: 'No', value: 'No' },
              ]}
            />
            <div className="pb-2"></div>
          </div>

          <div>
            <CustomSelectField
              name="food_allergies"
              label="Food allergies"
              options={[
                { label: 'Yes', value: 'Yes' },
                { label: 'No', value: 'No' },
              ]}
            />
          </div>

          <div>
            <CustomMultiLineTextField
              name="food_allergy_list"
              label="List of food allergies"
              rows="2"
            />
          </div>

          <div>
            <CustomSelectField
              name="catheter"
              label="Catheter"
              options={[
                { label: 'Yes', value: 'Yes' },
                { label: 'No', value: 'No' },
              ]}
            />
          </div>

          <div>
            <CustomTextField
              name="medical_equipment"
              label="Medical equipment, e.g. walking stick"
            />
          </div>

          <div>
            <CustomTextField
              name="other_medical_issues"
              label="Other medical issues"
            />
          </div>

          <h1 className="pb-3 pt-5 text-xl font-semibold">Other Information</h1>

          <div>
            <CustomTextField
              name="interests"
              label="Interests/hobbies, past or present?"
            />
          </div>

          <div>
            <CustomTextField
              name="topics_to_avoid"
              label="Anything that upsets/agitates?"
            />
          </div>

          <div>
            <CustomSelectField
              name="blue_badge_holder"
              label="Blue Badge holder"
              options={[
                { label: 'Yes', value: 'Yes' },
                { label: 'No', value: 'No' },
              ]}
            />
          </div>

          <div>
            <CustomTextField
              name="other_info"
              label="Other useful information?"
            />
          </div>

          <div>
            <CustomSelectField
              name="gdpr_form_signed"
              label="GDPR consent form signed"
              options={[
                { label: 'Yes', value: 'Yes' },
                { label: 'No', value: 'No' },
              ]}
            />
          </div>

          <div>
            <UploadWidget />
          </div>

          <div>
            <CustomTextField
              name="gdpr_form_link"
              label="GDPR Form Link"
              readOnly={true}
            />
          </div>

          <h1 className="pb-3 pt-5 text-xl font-semibold">
            Carer Specific Information
          </h1>

          <div>
            <CustomTextField
              name="cared_for_medical_conditions"
              label="Cared-for medical condition"
            />
          </div>

          <div>
            <Field
              type="hidden"
              autoComplete="off"
              id="inputCreateMember"
              name="last_updated_by"
              placeholder=" "
            />
          </div>

          <div>
            <CustomSelectField
              type="hidden"
              name="is_deceased"
              label="Deceased"
              options={[
                { label: 'Yes', value: 'Yes' },
                { label: 'No', value: 'No' },
              ]}
            />
          </div>

          <div className="flex flex-col items-center pt-7">
            <Button variant="contained" size="large" type="submit">
              Create Member
            </Button>
          </div>
          <div className="pb-20">
            <Snackbar
              open={open}
              autoHideDuration={5000}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              sx={{ position: 'absolute' }}
            >
              <Alert
                severity={severity}
                variant="filled"
                sx={{ width: '100%' }}
              >
                {message}
              </Alert>
            </Snackbar>
          </div>
        </Form>
      </Formik>
    </div>
  );
});
export default MembersCreate;
