import React, { useEffect, useState } from 'react'
import axios from 'axios'
import StaffEdit from '../Staff/StaffEdit'


function StaffByID(props) {
  
  const [staffData, setStaffData] = useState([])
  
  
    useEffect(() => {
      axios.get(process.env.REACT_APP_BE_URL+`/staff/byId/${props.id}`)
      .then((response) => {
        setStaffData(response.data);
      })
    }, [props.id])
    // console.log(staffData)

    //Conditional rendering - only displays the edit form if a row has been clicked in the table.  
    //Prevents user filling in the form without clicking a row, which would crash node due to lack of staff_id 
    if (props.id === undefined) 
     {return}
    else {  
      return (
          //next line takes props from DataTable.jsx - where the prop = id, which in turn equals the row id clicked in the table
          <>
          <StaffEdit staffData={staffData}/></>
      )}
}

export default StaffByID
