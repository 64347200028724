import React, { useState } from 'react';
import { BsArrowLeftShort, BsPencil, BsCalendar2Event } from 'react-icons/bs';
import { RiSettings4Line } from 'react-icons/ri';
import { IoPeopleOutline, IoCalendarNumberOutline } from 'react-icons/io5';
import { AiOutlineUser } from 'react-icons/ai';
import { MdOutlineMedicalServices } from 'react-icons/md';
import { FiMessageSquare } from 'react-icons/fi';
import { FiLogOut } from 'react-icons/fi';
import { FaCarSide } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import ADCA_Logo from '../assets/ADCALogo.png';

const Navbar = () => {
  const menus = [
    {
      key: '1',
      name: 'Notifications',
      link: '/notifications',
      icon: FiMessageSquare,
      margin: true,
    },
    {
      key: '2',
      name: 'Members',
      link: '/members',
      icon: IoPeopleOutline,
      margin: true,
    },
    { key: '3', name: 'Staff', link: '/staff', icon: AiOutlineUser },
    { key: '4', name: 'Events', link: '/events', icon: BsCalendar2Event },
    {
      key: '5',
      name: 'Services',
      link: '/services',
      icon: MdOutlineMedicalServices,
    },
    {
      key: '6',
      name: 'Member Comments',
      link: '/memberscomments',
      icon: BsPencil,
      margin: true,
    },
    {
      key: '7',
      name: 'Member Events',
      link: '/memberevents',
      icon: IoCalendarNumberOutline,
    },
    {
      key: '8',
      name: 'Member Services',
      link: '/memberservices',
      icon: FaCarSide,
    },
    {
      key: '9',
      name: 'User Settings',
      link: '/usersettings',
      icon: RiSettings4Line,
      margin: true,
    },
    { key: '10', name: 'Logout', link: '/logout', icon: FiLogOut },
  ];
  const [open, setOpen] = useState(true);
  return (
    <section className="flex gap-6">
      <div
        className={`bg-dark-purple min-h-screen ${
          open ? 'w-60' : 'w-16'
        } duration-500 text-gray-100 px-4 p-5`}
      >

        <div className=" flex relative">
          <BsArrowLeftShort
            size={26}
            className={`cursor-pointer absolute -right-7 border-dark-purple border-2 rounded-full bg-white text-dark-purple top- w-7 ${
              !open && 'rotate-180'
            }`}
            onClick={() => setOpen(!open)}
          />
        </div>
        <img
          className="mx-auto"
          src={ADCA_Logo}
          width={110}
          alt="Missing ADCA Logo"
        />
        <div className="flex flex-col gap-4 relative top-4">
          {menus?.map((menu, key) => (
            <Link
              to={menu?.link}
              key={key}
              className={` ${
                menu?.margin && 'mt-5'
              } group flex items-center text-sm gap-3.5 font-medium p-2 hover:bg-light-white rounded-md`}
            >
              <div>{React.createElement(menu?.icon, { size: '20' })}</div>
              <h2
                style={{
                  transitionDelay: `${key + 3}00ms`,
                }}
                className={`relative whitespace-pre duration-500 ${
                  !open && 'opacity-0 translate-x-20 overflow-hidden'
                }`}
              >
                {menu?.name}
              </h2>
            </Link>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Navbar;
