import React, { useState } from 'react';
import { DataGrid, GridToolbarQuickFilter } from '@mui/x-data-grid';
import MemberServicesByID from '../MemberServices/MemberServicesByID';

//Function that adds the Search box above the data grid
function QuickSearchToolbar() {
  return <GridToolbarQuickFilter />;
}

const DataTableMemberServices = ({ rows, columns, loading, sx }) => {
  //state to manage cell click
  const [finalClickInfo, setFinalClickInfo] = useState('');
  const handleOnCellClick = (params) => {
    setFinalClickInfo(params);
  };

  //State to manage pagination - default 10 rows per screen
  const [pageSize, setPageSize] = useState(5);

  return (
    <>
      <div className="w-full">
        <DataGrid
          rows={rows}
          columns={columns}
          loading={loading}
          getRowId={(row) => row.member_service_id}
          sx={sx}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[5, 10, 20]}
          components={{ Toolbar: QuickSearchToolbar }} // activates the searchbar above the data grid
          onCellClick={handleOnCellClick}
          initialState={{
            sorting: {
              sortModel: [{ field: 'service_date', sort: 'desc' }],
            },
          }}
        />
      </div>
      <br></br>
      <MemberServicesByID id={finalClickInfo.id} />
    </>
  );
};

export default DataTableMemberServices;
