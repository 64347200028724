import React from 'react';
import { useEffect, useState } from 'react';
import DataTableMemberEvents from '../DataTable/DataTableMemberEvents';
import axios from 'axios';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import { useRecoilValue } from 'recoil';
import { memberEventButtonClickedState } from '../Atoms/atoms';

const columns = [
  {
    field: 'member_event_id',
    hide: true,
  },

  {
    field: 'Edit',
      headername: 'Edit',
      width: 100,
      sortable: false, 
      filterable: false,
      disableClickEventBubbling: true,
      renderCell: () => {
        return (
          <Button
            variant="contained"
            color="primary"
            startIcon={<EditIcon />}
          ></Button>
        );
      },
  },

  { field: 'event_name', headerName: 'Event Name', width: 250 },
  { field: 'event_date', headerName: 'Event Date', width: 150 },
];

const MemberEventsTable = () => {
  const [memberEvents, setMemberEvents] = useState([]);
  // console.log('memberevents = ' + JSON.stringify(memberEvents))

  const flag = useRecoilValue(memberEventButtonClickedState);

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BE_URL + '/memberevents')
      .then((res) => res.data)
      .then((data) => setMemberEvents(data));
  }, [flag]);

  return (
    <>
      <div>
        <DataTableMemberEvents
          rows={memberEvents}
          columns={columns}
          loading={!memberEvents.length}
          sx={{
            height: '424px',
            '& .MuiDataGrid-columnHeaderTitle': { fontWeight: 700 },
          }}
        />
      </div>
    </>
  );
};

export default MemberEventsTable;
