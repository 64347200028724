import React from 'react';
import { useEffect, useState } from 'react';
import DataTableServices from '../DataTable/DataTableServices';
import axios from 'axios';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import { useRecoilValue } from 'recoil';
import { servicesButtonClickedState } from '../Atoms/atoms';

const columns = [
  {
    field: 'service_id',
    hide: true,
  },

  {
    field: 'Edit',
    headername: 'Edit',
    width: 100,
    sortable: false,
    filterable: false,
    disableClickEventBubbling: true,
    renderCell: () => {
      return (
        <Button
          variant="contained"
          color="primary"
          startIcon={<EditIcon />}
        ></Button>
      );
    },
  },

  { field: 'service_name', headerName: 'Service Name', width: 250 },
];

const ServicesTable = () => {
  const [services, setServices] = useState([]);
  // console.log(services)

  const flag = useRecoilValue(servicesButtonClickedState);
  // console.log(flag)

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BE_URL + '/services')
      .then((res) => res.data)
      .then((data) => setServices(data));
  }, [flag]);
  return (
    <>
      <div>
        <DataTableServices
          rows={services}
          columns={columns}
          loading={!services.length}
          sx={{
            height: '424px',
            '& .MuiDataGrid-columnHeaderTitle': { fontWeight: 700 },
          }}
        />
      </div>
    </>
  );
};

export default ServicesTable;
