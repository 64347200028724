import React from 'react';
import { Field, ErrorMessage } from 'formik';
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
} from '@mui/material';

const CustomSelectFieldSaveById = ({ label, name, options, readOnly }) => {
  return (
    <div className="pb-4">
      <Field name={name}>
        {({ field, meta, form }) => (
          <FormControl
            sx={{
              width: '100%',
            }}
            variant="outlined"
            error={Boolean(meta.touched && meta.error)}
          >
            <InputLabel id={`${name}-label`}>{label}</InputLabel>
            <Select
              sx={{
                borderRadius: '8px',
                // Custom styles
              }}
              label={label}
              labelId={`${name}-label`}
              id={name}
              readOnly={readOnly}
              {...field}
              value={field.value || ''}
              onChange={(event) => {
                const { value } = event.target;
                form.setFieldValue(field.name, value);
              }}
              renderValue={(selected) => {
                const selectedOption = options.find(
                  (option) => option.key === selected
                );
                return selectedOption ? selectedOption.value : '';
              }}
            >
              {options.map((option) => (
                <MenuItem key={option.key} value={option.key}>
                  {option.value}
                </MenuItem>
              ))}
            </Select>
            <ErrorMessage name={name} component={FormHelperText} />
          </FormControl>
        )}
      </Field>
    </div>
  );
};

export default CustomSelectFieldSaveById;
