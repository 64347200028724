import React, { useEffect, useState } from 'react'
import axios from 'axios'
import MembersCommentsEdit from '../MembersComments/MembersCommentsEdit'
import { useRecoilValue } from 'recoil'
import { memberIdForCommentsState } from '../Atoms/atoms';

function MembersCommentsByID(props) {
  
  const [membersCommentsData, setMembersCommentData] = useState([])
  
  const member = useRecoilValue(memberIdForCommentsState)
  // console.log('member = ' + JSON.stringify(member))

    useEffect(() => {
      axios.get(process.env.REACT_APP_BE_URL+`/memberscomments/byId/${member}`)
      .then((response) => {
        setMembersCommentData(response.data);
      })
    }, [member])
    //  console.log('props =' + JSON.stringify(props))
    // console.log('membersCommentsData in byId.jsx' + JSON.stringify(membersCommentsData))

    
    //Conditional rendering - only displays the edit form if a row has been clicked in the table.  
    //Prevents user filling in the form without clicking a row, which would crash node due to lack of id 
    if (props.id.id === undefined) 
     {return}
    else {  
      return (
          //next line takes props from DataTableMemberComments.jsx - where the prop = id, which in turn equals the member_comment_id
          <>
          <MembersCommentsEdit membersCommentsData={membersCommentsData}/></>
      )}
}

export default MembersCommentsByID
