import React from 'react';
import { FastField } from 'formik';
import { TextField} from '@mui/material';

const CustomTextField = ({ name, label, rows, readOnly, ...props }) => {

  return (
    <div className="pb-4">
      <FastField name={name}>
        {({ field, meta }) => (
            <TextField
              {...field}
              {...props}
              label={label}
              rows={rows}
              error={Boolean(meta.touched && meta.error)} // Convert meta.error to boolean
              helperText={meta.touched && meta.error}
              sx={{
                width: '100%',
              }}
              variant="outlined"
              InputProps={{
                readOnly: readOnly,

              }}
            />
        )}
      </FastField>
    </div>
  );
};

export default React.memo(CustomTextField);