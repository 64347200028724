import React, { useState, useEffect, useRef } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import { useRecoilState, useRecoilValue } from 'recoil';
import { memberEventButtonClickedState } from '../Atoms/atoms';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { userNameState } from '../Atoms/atoms';
import CustomSelectField from '../CustomComponents/CustomSelectField';
import PrintOutButton from '../CustomComponents/PrintOutButton';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import CustomDateFieldEdit from '../CustomComponents/CustomDateFieldEdit';

import {
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material';

//React-Query function to post data to member_events table
const useMemberEventsEditMutationME = () => {
  return useMutation(async (formPayload) => {
    //Below variables extract data from first object (i.e. object zero) in the array in formPayload
    const eventDate = formPayload[0].event_date;
    const eventID = formPayload[0].event_id;

    // editing deletes all event->date combinations first, then creates new rows
    // eslint-disable-next-line no-sequences
    return (
      await axios.delete(
        process.env.REACT_APP_BE_URL + '/memberevents/memberdelete',
        {
          data: { event_date: eventDate, eventID: eventID },
        }
      ),
      await axios.post(
        process.env.REACT_APP_BE_URL + '/memberevents/mememberevents',
        formPayload
      )
      //,console.log('payload_memberevents field = ' + JSON.stringify(formPayload))
    );
  });
};

//React-Query function to post data to staff_events table
const useMemberEventsEditMutationSE = () => {
  return useMutation(async (formPayload) => {
    //Below variables extract data from first object (i.e. object zero) in the array in formPayload
    const eventDate = formPayload[0].event_date;
    const eventID = formPayload[0].event_id;
    // editing deletes all event->date combinations first, then creates new rows
    // eslint-disable-next-line no-sequences
    return (
      await axios.delete(
        process.env.REACT_APP_BE_URL + '/memberevents/staffdelete',
        {
          data: { event_date: eventDate, eventID: eventID },
        }
      ),
      await axios.post(
        process.env.REACT_APP_BE_URL + '/memberevents/mestaffevents',
        formPayload
      )

      //,console.log('payload_staffevents field = ' + JSON.stringify(formPayload))
    );
  });
};

//Variable to store Tailwind css for 'Label' elements of Formik function
const formikLabel =
  'absolute text-[17px] text-gray-500 duration-300 transform -translate-y-5 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-6 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-5 left-2.5';

//Variable to store Tailwind css for 'Field' elements of Formik function
const formikFieldReadOnly =
  'pointer-events-none my-px block px-2.5 pb-2.5 pt-4 w-full text-[17px] text-gray-900 bg-transparent rounded-lg border border-gray-400 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer';

//Main function - creates Formik form
function MemberEventsEdit(props) {
  const { mutate: memberEventMutate } = useMemberEventsEditMutationME();
  const { mutate: staffEventMutate } = useMemberEventsEditMutationSE();

  const [parsedMemberName, setParsedMemberName] = useState([]);
  //console.log('parsedMemberName', parsedMemberName)

  const [parsedStaffName, setParsedStaffName] = useState([]);
  //console.log('parsedStaffName =', parsedStaffName)

  // State for events data to populate Event Name dropdown
  const [eventName, setEventName] = useState([]);
  //console.log('eventName', eventName)

  // Gets previously selected event name from events table
  useEffect(() => {
    if (props.memberEventData !== null) {
      //This, along with second curly bracket at bottom of function, stops the function running when the props are null - this prevents a warning/error message in console
      async function fetchData() {
        const request = await axios.get(
          process.env.REACT_APP_BE_URL +
            `/memberevents/eventname/${props.memberEventData.event_id}`
        );
        //setEventName(request.data.event_name);
        setEventName(request.data);
        return request;
      }
      fetchData();
    }
  }, [props.memberEventData]);

  //Gets logged in username from auth.js
  const userName = useRecoilValue(userNameState);
  //console.log('userName from members edit form= ' + JSON.stringify(userName))

  //Formik initial values
  const initialValues = {
    member_id: parsedMemberName ?? '', // replaced line above so as to remove console warning about passing an array to the select field in 'value'
    event_id: eventName.event_name ?? '',
    event_date: props?.memberEventData?.event_date ?? '',
    staff_id: parsedStaffName ?? '',
    //Hidden Fields
    last_updated_by: userName.user.displayName,
  };
  //console.log('initial values =', initialValues);

  //Yup validation, including checking for arrays in fields member_id and staff_id
  const validationSchema = Yup.object().shape({
    member_id: Yup.array()
      .required('*Member Name is required')
      .min(1, '*You must select one or more members')
      .of(
        Yup.object().shape({
          id: Yup.number().required(),
          FullName: Yup.string().required(),
        })
      ),
    event_id: Yup.string().required('*Event Name is required'),
    event_date: Yup.date()
      .required('*Event Date is required')
      .typeError(
        '*Event Date must be a valid date, and in the format YYYY/MM/DD'
      ),
    staff_id: Yup.array()
      .required('*Staff Name is required')
      .min(1, '*You must select one or more staff / volunteers')
      .of(
        Yup.object().shape({
          id: Yup.number().required(),
          FullName: Yup.string().required(),
        })
      ),
  });

  // Recoil global state to trigger data table refresh after event edit button is clicked
  const [buttonIsClicked, setButtonIsClicked] = useRecoilState(
    memberEventButtonClickedState
  );

  // State for events data to populate Event Name dropdown
  const [event, setEvent] = useState([]);
  //console.log('events', event)

  // Gets array of event ids/names from event table
  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BE_URL + '/eventdropdown')
      .then((res) => res.data)
      .then((data) => setEvent(data));
  }, []);

  // State for member data to populate member Name dropdown
  const [member, setMember] = useState([]);
  //console.log('member', member)

  // Gets array of member ids/names from member table
  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BE_URL + '/memberevents/memberdropdown')
      .then((res) => res.data)
      .then((data) => setMember(data));
  }, []);

  // State for staff data to populate staff Name dropdown
  const [staff, setStaff] = useState([]);
  //console.log('staff =', staff)

  // Gets array of staff ids/names from staff table
  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BE_URL + '/staffdropdown')
      .then((res) => res.data)
      .then((data) => setStaff(data));
  }, []);

  // State for MUI snackbar popup open status
  const [open, setOpen] = useState(false);

  //State for MUI snackbar popup message
  const [message, setMessage] = useState('');

  //State for MUI snackbar popup severity
  const [severity, setSeverity] = useState();

  // Variable for property to close MUI snackbar popup
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  // Gets list of previously selected member names from member_events table
  useEffect(() => {
    if (props.memberEventData !== null) {
      //This, along with second curly bracket at bottom of function, stops the function running when the props are null - this prevents a warning/error message in console
      async function fetchData() {
        const request = await axios.get(
          process.env.REACT_APP_BE_URL +
            `/memberevents/membernames/${props.memberEventData.event_id}/${props.memberEventData.event_date}`
        );
        setParsedMemberName(request.data);
        return request;
      }
      fetchData();
    }
  }, [props.memberEventData]);

  // Gets list of previously selected staff names from staff_events table
  useEffect(() => {
    if (props.memberEventData !== null) {
      //This, along with second curly bracket at bottom of function, stops the function running when the props are null - this prevents a warning/error message in console
      async function fetchData() {
        const request = await axios.get(
          process.env.REACT_APP_BE_URL +
            `/memberevents/staffnames/${props.memberEventData.event_id}/${props.memberEventData.event_date}`
        );
        setParsedStaffName(request.data);
        return request;
      }
      fetchData();
    }
  }, [props.memberEventData]);

  // List of data to populate select fields, formatted as needed by custom MUI select component
  const formattedMembers = member.map((member) => ({
    id: `${member.member_id}`,
    FullName: `${member.forename} ${member.surname}`,
  }));
  //console.log('formatted members =' + JSON.stringify(formattedMembers));

  // List of data to populate select fields, formatted as needed by custom MUI select component
  const formattedStaff = staff.map((staff) => ({
    id: `${staff.staff_id}`,
    FullName: `${staff.full_name}`,
  }));
  //console.log('formatted staff =' + JSON.stringify(formattedStaff))

  // List of data to populate select fields, formatted as needed by custom MUI select component
  const formattedEvents = event.map((event) => ({
    label: `${event.event_name}`,
    key: `${event.event_id}`,
    value: `${event.event_name}`,
  }));
  //console.log('formatted events =' + JSON.stringify(formattedEvents))

  const handleChangeMembers = (event) => {
    const fullName = event.target.value.slice(-1).pop();
    const selectedPerson = formattedMembers.find(
      (u) => u.FullName === fullName
    );
    //console.log('fullName', fullName);
    //console.log('selectedPerson', selectedPerson)

    if (
      selectedPerson &&
      !parsedMemberName.some((person) => person.FullName === fullName)
    ) {
      setParsedMemberName((prevArray) => [...prevArray, selectedPerson]);
    } else {
      const updatedArray = parsedMemberName.filter(
        (p) => p.FullName !== fullName
      );
      //console.log('updatedArray', updatedArray);
      setParsedMemberName(updatedArray);
    }
    //console.log('event.target.value', event.target.value);
    //console.log('parsedMemberName', parsedMemberName);
  };

  const handleChangeStaff = (event) => {
    const fullName = event.target.value.slice(-1).pop();
    const selectedPerson = formattedStaff.find((u) => u.FullName === fullName);
    //console.log('staff fullName', fullName);
    //console.log('staff selectedPerson', selectedPerson);

    if (
      selectedPerson &&
      !parsedStaffName.some((person) => person.FullName === fullName)
    ) {
      setParsedStaffName((prevArray) => [...prevArray, selectedPerson]);
    } else {
      const updatedArray = parsedStaffName.filter(
        (p) => p.FullName !== fullName
      );
      //console.log('staff updatedArray', updatedArray);
      setParsedStaffName(updatedArray);
    }
    //console.log('event.target.value', event.target.value);
    //console.log('parsedMemberName', parsedMemberName);
  };

  //Function to handle saving form data
  const handleSaveForm = (values) => {
    //Passes all form props to FormValues state, for use in form printout

    //console.log('raw values =' + JSON.stringify(values));

    // Deconstructed data from form, in format needed for member_events table
    const memberEventOutput = values.member_id.map((member) => ({
      member_id: member.id.toString(),
      event_id: eventName.event_id,
      event_date: values.event_date,
      last_updated_by: values.last_updated_by,
    }));
    //console.log('memberEventOutput =' + JSON.stringify(memberEventOutput));

    // Deconstructed data from form, in format needed for member_events table
    const staffEventOutput = values.staff_id.map((staff) => ({
      staff_id: staff.id.toString(),
      event_id: eventName.event_id,
      event_date: values.event_date,
      last_updated_by: values.last_updated_by,
    }));
    //console.log('staffEventOutput =' + JSON.stringify(staffEventOutput));

    // Below is the function to send the data to react-query and to control the snackbar success or error messages
    memberEventMutate(memberEventOutput, {
      onSuccess: () => {
        setOpen(true);
        setMessage('Member-event edited!');
        setSeverity('success');
        setButtonIsClicked(buttonIsClicked + 1); //updates Recoil global state, to trigger data-table refetch of data
        //formik.resetForm();
      },
      onError: (response) => {
        setOpen(true);
        setMessage('Error, member-event not edited!');
        setSeverity('error');
        console.log(response);
      },
    });

    staffEventMutate(staffEventOutput, {
      onSuccess: () => {
        setOpen(true);
        setMessage('Member-event edited!');
        setSeverity('success');
        setButtonIsClicked(buttonIsClicked + 1); //updates Recoil global state, to trigger data-table refetch of data
        //formik.resetForm();
      },
      onError: (response) => {
        setOpen(true);
        setMessage('Error, member-event not edited!');
        setSeverity('error');
        console.log(response);
      },
    });
    setSaveCompleted(true);
    setFormValues(values);
  };

  // 4 functions below are code needed to enable printout and passing props at the correct time (i.e. when they are populated with data)

  const [saveCompleted, setSaveCompleted] = useState(false);

  //State to store values props for passing to printout component
  const [formValues, setFormValues] = useState(initialValues);

  //Used to save form values when the print button is pressed
  const saveChangesButtonRef = useRef(null);

  // Function to simulate clicking "Save Changes" button
  const simulateSaveChangesButtonClick = () => {
    if (saveChangesButtonRef.current) {
      saveChangesButtonRef.current.click();
      //
    }
  };

  return (
    <>
      <div className="createMemberEventPage px-5 relative">
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSaveForm}
        >
          <Form className="formContainer">
            <h1 className="pb-3 text-xl font-semibold">General Information</h1>

            <div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <CustomDateFieldEdit
                  name="event_date"
                  label="Event date"
                  readOnly={true}
                />
              </LocalizationProvider>
            </div>

            <div>
              <CustomSelectField
                name="event_id"
                label="Event Name"
                options={formattedEvents}
                readOnly={true}
              />
            </div>

            <div>
              <FormControl sx={{ mt: 0.5, width: '100%' }} size="small">
                <InputLabel id="multiple-checkbox-label">Members</InputLabel>
                <Select
                  labelId="member_id"
                  id="member_id"
                  multiple
                  value={parsedMemberName}
                  onChange={handleChangeMembers}
                  input={<OutlinedInput label="Members" />}
                  renderValue={(selected) => {
                    return selected.map((s, index) => (
                      <div key={index}>
                        <MenuItem
                          key={'menuItem' + index}
                          value={s}
                          disableGutters={true}
                        >
                          <ListItemText
                            key={'listItemText' + index}
                            primary={s.FullName}
                          />
                        </MenuItem>
                      </div>
                    ));
                  }}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                    style: {
                      maxHeight: 400,
                    },
                  }}
                >
                  {formattedMembers.map((name) => {
                    return (
                      <MenuItem key={name.id} value={name.FullName}>
                        <ListItemText primary={name.FullName} />
                      </MenuItem>
                    );
                  })}
                </Select>
                <div className="pb-2">
                  <ErrorMessage
                    name="member_id"
                    component="span"
                    className="text-red-600"
                  />
                </div>
              </FormControl>
            </div>

            <div>
              <FormControl sx={{ mt: 1.5, width: '100%' }} size="small">
                <InputLabel id="staff_id">Staff & Volunteers</InputLabel>
                <Select
                  labelId="staff_id"
                  id="staff_id"
                  multiple
                  value={parsedStaffName}
                  onChange={handleChangeStaff}
                  input={<OutlinedInput label="Staff & Volunteers" />}
                  renderValue={(selected) => {
                    return selected.map((s, index) => (
                      <div key={index}>
                        <MenuItem
                          key={'menuItem' + index}
                          value={s}
                          disableGutters={true}
                        >
                          <ListItemText
                            key={'listItemText' + index}
                            primary={s.FullName}
                          />
                        </MenuItem>
                      </div>
                    ));
                  }}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                    style: {
                      maxHeight: 400,
                    },
                  }}
                >
                  {formattedStaff.map((name) => {
                    return (
                      <MenuItem key={name.id} value={name.FullName}>
                        <ListItemText primary={name.FullName} />
                      </MenuItem>
                    );
                  })}
                </Select>
                <div className="pb-2">
                  <ErrorMessage
                    name="staff_id"
                    component="span"
                    className="text-red-600"
                  />
                </div>
              </FormControl>
            </div>

            <div className="pb-2 relative">
              <Field
                type="hidden"
                className={formikFieldReadOnly}
                autoComplete="off"
                id="inputCreateMember"
                name="last_updated_by"
                placeholder=" "
              />
              <label className={formikLabel}></label>
              <ErrorMessage
                name="last_updated_by"
                component="span"
                className="text-red-600"
              />
            </div>

            <div className="flex flex-col items-center pt-4">
              <Button
                variant="contained"
                size="large"
                type="submit"
                ref={saveChangesButtonRef}
              >
                Save Changes
              </Button>
            </div>

            <div>
              <PrintOutButton
                onClick={simulateSaveChangesButtonClick}
                formValues={saveCompleted ? formValues : initialValues}
              />
            </div>

            <div className="pb-20">
              <Snackbar
                open={open}
                autoHideDuration={5000}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                sx={{ position: 'absolute' }}
              >
                <Alert
                  severity={severity}
                  variant="filled"
                  sx={{ width: '100%' }}
                >
                  {message}
                </Alert>
              </Snackbar>
            </div>
          </Form>
        </Formik>
      </div>
    </>
  );
}

export default MemberEventsEdit;
