import React from 'react';
import { useField } from 'formik';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const CustomTimeField = ({ label, ...props }) => {
  const [field, meta, helpers] = useField(props);
console.log('field', field)

  const handleChange = (value) => {
    const formattedValue = value ? value.format('HH:mm') : null;
    helpers.setValue(formattedValue);
  };

  return (
    <div className="pb-4">

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <TimePicker
          format="HH:mm"
          sx={{
            width: '100%',
          }}
          {...field}
          {...props}
          label={label}
          value={field.value || null}
          onChange={handleChange}
        />
        {meta.touched && meta.error ? <div>{meta.error}</div> : null}
      </LocalizationProvider>
    </div>
  );
};

export default CustomTimeField;
