import React, { useEffect, useState } from 'react';
import { useFormikContext, FastField } from 'formik';
import { TextField } from '@mui/material';
import axios from 'axios';

const MemberDependentField = (props) => {
  const { values: { member_id }, setFieldValue } = useFormikContext();
  //Below used to handle case where no data is passed in the dependent field.
  //This prevents the controlled/uncontrolled warning in React 
  const [fieldData, setFieldData] = useState('');

  useEffect(() => {
    if (member_id !== '') {
      axios
        .get(process.env.REACT_APP_BE_URL + `/memberservices/byId/${member_id}`)
        .then((response) => {
          setFieldData(response.data[props.name] || ''); // Set the value from the response or an empty string if it's undefined
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [member_id, props.name]);

  useEffect(() => {
    setFieldValue(props.name, fieldData);
  }, [props.name, fieldData, setFieldValue]);

  return (
    <div className="pb-4">
      <FastField name={props.name}>
        {({ field: fastField, meta: fastMeta }) => (
          <TextField
            {...fastField}
            {...props}
            label={props.label}
            rows={props.rows}
            value={fieldData} // Use the fieldData state as the value
            error={Boolean(fastMeta.touched && fastMeta.error)}
            helperText={fastMeta.touched && fastMeta.error}
            sx={{
              width: '100%',
            }}
            variant="outlined"
            InputProps={{
              readOnly: props.readOnly,
            }}
          />
        )}
      </FastField>
    </div>
  );
};

export default React.memo(MemberDependentField);
