import React from 'react'
import { useEffect, useState } from 'react';
import DataTableStaff from '../DataTable/DataTableStaff';
import axios from 'axios'
import Button from '@mui/material/Button'
import EditIcon from '@mui/icons-material/Edit'
import { useRecoilValue } from 'recoil'
import { staffButtonClickedState } from '../Atoms/atoms'
 
  const columns = [

    { 
      field: 'staff_id', 
      hide:true 
      },
    
    {field: 'Edit', 
      headername:'Edit', 
      width:100, 
      sortable: false, 
      filterable: false, 
      disableClickStaffBubbling: true,
      renderCell: () => {
        return (
          <Button 
            variant="contained" 
            color="primary" 
            startIcon={<EditIcon />}>
          </Button>
      )
      }
      },
    
    { field: 'full_name', headerName: 'Name', width: 150 },
    { field: 'email_address', headerName: 'Email Address', width: 150 },
    { field: 'phone_number', headerName: 'Phone Number', width: 150 },
    { field: 'is_event_volunteer', headerName: 'Volunteer Status', width: 150 },
    { field: 'is_driver', headerName: 'Driver Status', width: 150 },
    { field: 'is_employee', headerName: 'Employee Status', width: 150 },
  ];


const StaffTable = () => {

    const[staff, setStaff] = useState([])
    // console.log(staff)

    const flag = useRecoilValue(staffButtonClickedState)
    // console.log(flag)

      useEffect(() => {
        axios.get(process.env.REACT_APP_BE_URL+'/staff')
            .then((res) => res.data)
            .then(data => setStaff(data))
    }, [flag])
    return (
          <><div>
        <DataTableStaff
          rows={staff}
          columns={columns}
          loading={!staff.length}
          sx={{height: '424px', '& .MuiDataGrid-columnHeaderTitle': {fontWeight: 700,} }}/>
      </div></>
    )
}

export default StaffTable