import React from 'react'
import { useEffect, useState } from 'react';
import DataTable from '../DataTable/DataTableMembersComments';
import axios from 'axios'
import Button from '@mui/material/Button'
import EditIcon from '@mui/icons-material/Edit'
import { useRecoilValue } from 'recoil'
import { membersCommentsButtonClickedState } from '../Atoms/atoms'
 
  const columns = [

    { 
      field: 'member_id', 
      hide:true
      },
    
    {field: 'Edit', 
      headername:'Edit', 
      width:100, 
      sortable: false, 
      filterable: false, 
      disableClickEventBubbling: true,
      renderCell: () => {
        return (
          <Button variant="contained" color="primary" startIcon={<EditIcon />}></Button>
        )}
    },
    { field: 'forename', headerName: 'Forename', width: 150 },
    { field: 'surname', headerName: 'Surname', width: 150 },
    { field: 'address_1', headerName: 'Address', width: 150 },
    { field: 'most_recent_comment', headerName: 'Date', width: 115 },    
    { field: 'last_updated_by', headerName: 'Last Updated By', width: 150 },
    { field: 'member_comment_id', hide:true },
  ];

const MembersCommentsTable = () => {

    const[membersComments, setMembersComments] = useState([])
    // console.log('membersComments = ' + JSON.stringify(membersComments))

    const flag = useRecoilValue(membersCommentsButtonClickedState)
    // console.log(flag)

      useEffect(() => {
        axios.get(process.env.REACT_APP_BE_URL+'/memberscomments')
            .then((res) => res.data)
            .then(data => setMembersComments(data))
    }, [flag])

    
    return (
          <><div>
        <DataTable
          rows={membersComments}
          columns={columns}
          loading={!membersComments.length}
          // sx={membersTableStyles} 
          sx={{height: '424px', '& .MuiDataGrid-columnHeaderTitle': {fontWeight: 700,} }}/>
      </div></>
    )
}

export default MembersCommentsTable