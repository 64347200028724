import React from 'react';
import { FastField } from 'formik';
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
} from '@mui/material';

const SelectField = ({ label, name, options, readOnly, type }) => {
  return (
    <div className="pb-4">
    <FastField name={name}>
      {({ field, meta, form }) => (
        <FormControl
          sx={{
            width: '100%',
            display: type === 'hidden' ? 'none' : '',
            borderRadius: '0px',
          }}
          variant="outlined"
          error={Boolean(meta.touched && meta.error)} // Set the error prop based on meta.touched and meta.error
        >
          <InputLabel id={`${name}-label`}>{label}</InputLabel>
          <Select
            label={label}
            labelId={`${name}-label`}
            id={name}
            readOnly={readOnly}
            {...field}
            value={field.value || ''}
            onChange={(event) => {
              const { value } = event.target;
              form.setFieldValue(field.name, value); // Use form.setFieldValue to update the formik values
            }}
          >
            {options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
          {meta.touched &&
            meta.error && ( // Display the helper text if the field has been touched and there is an error
              <FormHelperText>{meta.error}</FormHelperText>
            )}
        </FormControl>
      )}
    </FastField>
    </div>
  );
};

export default React.memo(SelectField);
