import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import Button from '@mui/material/Button';
import ComponentToPrint from './ComponentToPrint';

const PrintOutButton = ({ formValues, onClick }) => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const getPageMargins = () => {
    return `@page { margin: 1cm !important; }`;
  };

  const handleButtonClick = () => {
    if (onClick) {
      onClick();
    }
    handlePrint();
  };

  return (
    <>
      <style>{getPageMargins()}</style>
      <div style={{ display: 'none' }}>
        <ComponentToPrint ref={componentRef} formValues={formValues} />
      </div>
      <div className="flex flex-col items-center pt-4">
        <Button variant="contained" size="large" onClick={handleButtonClick}>
          Print Form
        </Button>
      </div>
    </>
  );
};

export default PrintOutButton;
