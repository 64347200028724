import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup'; //yup does form validation
import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import { useRecoilState, useRecoilValue } from 'recoil';
import { servicesButtonClickedState, userNameState } from '../Atoms/atoms';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import CustomTextField from '../CustomComponents/CustomTextField';

//react-query useMutation code
const useServicesCreateMutation = () => {
  return useMutation(async (formPayload) => {
    return await axios.put(
      process.env.REACT_APP_BE_URL + '/services/ById',
      formPayload
    );
  });
};

//Main function/custom useMutation Hook - creates Formik form
function ServicesEdit(props) {
  const { mutate } = useServicesCreateMutation();
  // console.log('props =', props)

  //Gets logged in username from auth.js
  const userName = useRecoilValue(userNameState);
  //console.log('userName from members edit form= ' + JSON.stringify(userName))

  // //Formik initial values
  const initialValues = {
    service_name: props?.ServicesData?.service_name ?? '', //The ?? '' part populates the form field with blank if the props contain no data/undefined (e.g. before a row is clicked in the table)
    //Hidden Fields
    last_updated_by: userName.user.displayName,
    service_id: props?.ServicesData?.service_id ?? '',
  };

  // Yup field validation
  const validationSchema = Yup.object().shape({
    service_name: Yup.string()
      .required('*Service Name is required')
      .max(70, 'Service Name can be a maximum of 70 characters'),
  });

  // Recoil global state to trigger data table refresh after event edit button is clicked
  const [buttonisClicked, setButtonIsClicked] = useRecoilState(
    servicesButtonClickedState
  );

  // State for MUI snackbar popup open status
  const [open, setOpen] = useState(false);

  //State for MUI snackbar popup message
  const [message, setMessage] = useState('');

  //State for MUI snackbar popup severity
  const [severity, setSeverity] = useState();

  // Variable for property to close MUI snackbar popup
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <div className="createEventPage px-5 relative">
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, formik) => {
            mutate(values, {
              onSuccess: () => {
                setOpen(true);
                setMessage('Service changes saved!');
                setSeverity('success');
                setButtonIsClicked(buttonisClicked + 1); //updates Recoil global state, to trigger data-table refetch of data
                //formik.resetForm();
              },
              onError: (response) => {
                setOpen(true);
                setMessage('Error, service changes not saved!');
                setSeverity('error');
                console.log(response);
              },
            });
          }}
        >
          <Form className="formContainer">
            <h1 className="pb-3 text-xl font-semibold">General Information</h1>

            <div>
              <CustomTextField name="service_name" label="Service Name" />
            </div>

            <div>
              <Field
                type="hidden"
                autoComplete="off"
                id="inputCreateMember"
                name="last_updated_by"
                placeholder=" "
              />
            </div>

            <div className="flex flex-col items-center pt-7">
              <Button variant="contained" size="large" type="submit">
                Save Changes
              </Button>
            </div>

            <div className="pb-20">
              <Snackbar
                open={open}
                autoHideDuration={5000}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                sx={{ position: 'absolute' }}
              >
                <Alert
                  severity={severity}
                  variant="filled"
                  sx={{ width: '100%' }}
                >
                  {message}
                </Alert>
              </Snackbar>
            </div>
          </Form>
        </Formik>
      </div>
    </>
  );
}

export default ServicesEdit;
