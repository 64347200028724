import React from 'react';
import { useField, useFormikContext } from 'formik';
import axios from 'axios';

const StaffDependentField = (props) => {
  const {
    values: { staff_id },
    setFieldValue,
  } = useFormikContext();
  const [field] = useField(props);

  React.useEffect(() => {
    if (staff_id !== '') {
      axios
        .get(process.env.REACT_APP_BE_URL + `/memberservices/byStaffId/${staff_id}`)
        .then((response) => {
          setFieldValue(props.name, response.data[props.name]); // Set the value in the Formik values
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [staff_id, setFieldValue, props.name]);

  return (
    <>
      <input {...props} {...field} />
    </>
  );
};

export default StaffDependentField;
