import React, { useEffect, useState } from 'react'
import axios from 'axios'
import MemberServicesEdit from '../MemberServices/MemberServicesEdit'


function MemberServicesByID(props) {
  
  const [memberServiceData, setMemberServiceData] = useState([])
  useEffect(() => {
    axios.get(process.env.REACT_APP_BE_URL+`/memberservices/servicebyId/${props.id}`)
    .then((response) => {
      setMemberServiceData(response.data);
      // console.log('props = ' + JSON.stringify(memberServicesData))
    })
  }, [props.id])

      //Conditional rendering - only displays the edit form if a row has been clicked in the table.  
    //Prevents user filling in the form without clicking a row, which would crash node due to lack of event_id 
    if (props.id === undefined) 
     {return}
    else {  
      return (
          //next line takes props from DataTableMemberServices.jsx - where the prop = id, which in turn equals the 'row id' clicked in the table, which contains 'member_service_id'
          <>
          <MemberServicesEdit memberServiceData={memberServiceData}/></>
      )}
}

export default MemberServicesByID
