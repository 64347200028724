import React from 'react';
import { Field, ErrorMessage } from 'formik';
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  ListItemText,
  FormHelperText,
} from '@mui/material';

const MultipleSelectField = ({ label, name, options, initialValues }) => {
  return (
    <div className="pb-4">
      <Field name={name}>
        {({ field, meta }) => (
          <FormControl
            sx={{
              width: '100%',
              borderRadius: '0px',
            }}
            variant="outlined"
            error={Boolean(meta.touched && meta.error)}
          >
            <InputLabel id={`${name}-label`}>{label}</InputLabel>
            <Select
              sx={{}}
              multiple
              label={label}
              labelId={`${name}-label`}
              id={name}
              {...field}
              value={field.value || []}
              onChange={(event) => {
                const { value } = event.target;
                field.onChange({
                  target: {
                    name: field.name,
                    value: value,
                  },
                });
              }}
              renderValue={(selected) => (
                <div>
                  {selected.map((key) => (
                    <ListItemText
                      key={key}
                      primary={
                        options.find((option) => option.key === key)?.value
                      }
                    />
                  ))}
                </div>
              )}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                style: {
                  maxHeight: 400,
                },
              }}
            >
              {/* The below generates the list of options, determining that the 'value' that are displayed to the user, whilst the 'key' is saved to the database */}
              {options.map((option) => (
                <MenuItem key={option.key} value={option.key}>
                  {option.value}
                </MenuItem>
              ))}
            </Select>
            <ErrorMessage name={name} component={FormHelperText} />
          </FormControl>
        )}
      </Field>
    </div>
  );
};

export default MultipleSelectField;
