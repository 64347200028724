import React from 'react';
import { useEffect, useState } from 'react';
import DataTableUserSettings from '../DataTable/DataTableUserSettings';
import axios from 'axios';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import { useRecoilValue } from 'recoil';
import { userSettingsButtonClickedState } from '../Atoms/atoms';

const columns = [
  {
    field: 'staff_id',
    hide: true,
  },

  {
    field: 'Edit',
    headername: 'Edit',
    width: 100,
    sortable: false,
    filterable: false,
    disableClickStaffBubbling: true,
    renderCell: () => {
      return (
        <Button
          variant="contained"
          color="primary"
          startIcon={<EditIcon />}
        ></Button>
      );
    },
  },
  { field: 'username', headerName: 'Name', width: 150 },
  { field: 'google_id', headerName: 'Google ID', width: 150 },
  { field: 'is_sys_admin', headerName: 'Sys Admin', width: 150 },
  { field: 'is_member_maint_user', headerName: 'Member User', width: 150 },
  { field: 'is_services_maint_user', headerName: 'Services User', width: 150 },
  { field: 'last_updated_by', headerName: 'Updated By', width: 150 },
];

const UserSettingsTable = () => {
  const [UserSettings, setUserSettings] = useState([]);

  const flag = useRecoilValue(userSettingsButtonClickedState);
  // console.log(flag)

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BE_URL + '/usersettings')
      .then((res) => res.data)
      .then((data) => setUserSettings(data));
  }, [flag]);

  return (
    <>
      <div>
        <DataTableUserSettings
          rows={UserSettings}
          columns={columns}
          loading={!UserSettings.length}
          sx={{
            height: '424px',
            '& .MuiDataGrid-columnHeaderTitle': { fontWeight: 700 },
          }}
        />
      </div>
    </>
  );
};

export default UserSettingsTable;
