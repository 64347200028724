import MembersCommentsTable from '../components/MembersComments/MembersCommentsTable';

function MembersComments() {
  const collapsed = true;

  return (
    <>

      <div
        className={`bg-[#598DF9] text-orientation-mixed writing-mode-vertical text-gray-100 text-xl font-semibold indent-5 pr-0.5 pl-1.5 pt-10`} //text-orientation-mixed writing-mode-vertical are custom css utilities created in index.css to enable vertical text
      >
        Comments
      </div>

      <div
        className= {`bg-[#fbfbfd] ${collapsed ? "w-full p-5"  : "w-1 overflow-hidden opacity-0"} duration-700 whitespace-nowrap`}
      >
        <h1 className="text-xl font-bold pb-5">Member Comments Form</h1>
        <MembersCommentsTable/>
      </div>
    </>
  );
}

export default MembersComments;
