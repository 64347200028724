import { DataGrid } from '@mui/x-data-grid';

const DataTableNotificationsBirthdays = ({ rows, columns, loading, sx }) => {
  return (
    <>
      <div className="w-full">
        <DataGrid
          autoHeight={true}
          rows={rows}
          columns={columns}
          loading={loading}
          getRowId={(row) => row.member_id}
          sx={sx}
        />
      </div>
    </>
  );
};

export default DataTableNotificationsBirthdays;
