import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup'; //yup does form validation
import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import { useRecoilState, useRecoilValue } from 'recoil';
import { memberButtonClickedState } from '../Atoms/atoms';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { userNameState } from '../Atoms/atoms';
import CustomSelectField from '../CustomComponents/CustomSelectField';
import CustomTextField from '../CustomComponents/CustomTextField';
import CustomDateField from '../CustomComponents/CustomDateField';
import CustomMultiLineTextField from '../CustomComponents/CustomMultiLineTextField';
import { parseISO } from 'date-fns';
import UploadWidget from '../CustomComponents/UploadWidget';

//react-query useMutation code
const useMembersCreateMutation = () => {
  return useMutation(async (formPayload) => {
    return await axios.put(
      process.env.REACT_APP_BE_URL + '/members/ById',
      formPayload
    );
  });
};

//Main function/custom useMutation Hook - creates Formik form
function MembersEdit(props) {
  const { mutate } = useMembersCreateMutation();

  //Gets logged in username from auth.js
  const userName = useRecoilValue(userNameState);

  // //Formik initial values
  const initialValues = {
    member_type: props?.memberData?.member_type ?? '',
    forename: props?.memberData?.forename ?? '', //The ?? '' part populates the form field with blank if the props contain no data/undefined (e.g. before a row is clicked in the table)
    surname: props?.memberData?.surname ?? '', //the two questions marks after props and memberData mean ignore if null
    known_as: props?.memberData?.known_as ?? '',
    date_of_birth: props?.memberData?.date_of_birth
      ? parseISO(props.memberData.date_of_birth)
      : null,
    gender: props?.memberData?.gender ?? '',
    ethnicity: props?.memberData?.ethnicity ?? '',
    address_1: props?.memberData?.address_1 ?? '',
    address_2: props?.memberData?.address_2 ?? '',
    address_3: props?.memberData?.address_3 ?? '',
    town: props?.memberData?.town ?? '',
    postcode: props?.memberData?.postcode ?? '',
    directions: props?.memberData?.directions ?? '',
    phone_number: props?.memberData?.phone_number ?? '',
    email_address: props?.memberData?.email_address ?? '',
    next_of_kin_name: props?.memberData?.next_of_kin_name ?? '',
    next_of_kin_relationship: props?.memberData?.next_of_kin_relationship ?? '',
    next_of_kin_phone: props?.memberData?.next_of_kin_phone ?? '',
    next_of_kin_local: props?.memberData?.next_of_kin_local ?? '',
    emergency_contact_2_name: props?.memberData?.emergency_contact_2_name ?? '',
    emergency_contact_2_relationship:
      props?.memberData?.emergency_contact_2_relationship ?? '',
    emergency_contact_2_phone:
      props?.memberData?.emergency_contact_2_phone ?? '',
    doctor_name: props?.memberData?.doctor_name ?? '',
    surgery_name: props?.memberData?.surgery_name ?? '',
    doctor_phone: props?.memberData?.doctor_phone ?? '',
    medications_taken: props?.memberData?.medications_taken ?? '',
    impaired_hearing: props?.memberData?.impaired_hearing ?? '',
    impaired_vision: props?.memberData?.impaired_vision ?? '',
    impaired_mobility: props?.memberData?.impaired_mobility ?? '',
    fall_risk: props?.memberData?.fall_risk ?? '',
    dementia: props?.memberData?.dementia ?? '',
    impaired_communication: props?.memberData?.impaired_communication ?? '',
    wander_risk: props?.memberData?.wander_risk ?? '',
    diabetes: props?.memberData?.diabetes ?? '',
    food_allergies: props?.memberData?.food_allergies ?? '',
    food_allergy_list: props?.memberData?.food_allergy_list ?? '',
    catheter: props?.memberData?.catheter ?? '',
    medical_equipment: props?.memberData?.medical_equipment ?? '',
    other_medical_issues: props?.memberData?.other_medical_issues ?? '',
    gdpr_form_signed: props?.memberData?.gdpr_form_signed ?? '',
    gdpr_form_link: props?.memberData?.gdpr_form_link ?? '',
    interests: props?.memberData?.interests ?? '',
    topics_to_avoid: props?.memberData?.topics_to_avoid ?? '',
    blue_badge_holder: props?.memberData?.blue_badge_holder ?? '',
    other_info: props?.memberData?.other_info ?? '',
    cared_for_medical_conditions:
      props?.memberData?.cared_for_medical_conditions ?? '',
    member_id: props?.memberData?.member_id ?? '',
    last_updated_by: userName.user.displayName,
    is_deceased: props?.memberData?.is_deceased ?? '',
    deceased_date: undefined,
  };

  // Yup field validation
  const validationSchema = Yup.object().shape({
    member_type: Yup.string().required('*Member type is required'),

    forename: Yup.string()
      .required('*Forename is required')
      .max(35, 'Forename can be a maximum of 35 characters'),

    surname: Yup.string()
      .required('*Surname is required')
      .max(35, 'Surname can be a maximum of 35 characters'),

    known_as: Yup.string().max(
      35,
      'Likes To Be Known As can be a maximum of 35 characters'
    ),

    //Custom code to replace yup's standard (horrible) error message if the date entered is invalid, e.g. 10/05/5
    date_of_birth: Yup.string()
      .nullable() // Allow null values
      .when(['member_type'], {
        is: (member_type) =>
          member_type.includes('Carer') ||
          member_type.includes('Standard Member'),
        then: Yup.string()
          .nullable() // Allow the field to be nullable
          .required('*Date of birth is required')
          .test(
            'valid-date',
            '*Date of birth must be a valid date',
            (value) => {
              return value !== 'Invalid Date';
            }
          ),
      }),

    gender: Yup.string().when(['member_type'], {
      is: (member_type) => member_type.includes('Standard Member'),
      then: Yup.string().required('*Gender is required'),
    }),

    ethnicity: Yup.string().when(['member_type'], {
      is: (member_type) => member_type.includes('Standard Member'),
      then: Yup.string().required('*Ethnicity is required'),
    }),

    address_1: Yup.string()
      .when(['member_type'], {
        is: (member_type) =>
          member_type.includes('Standard Member') ||
          member_type.includes('Service User'),
        then: Yup.string().required('*Address Line 1 is required'),
      })
      .max(35, 'Address Line 1 can be a maximum of 35 characters'),

    address_2: Yup.string().max(
      35,
      'Address Line 2 can be a maximum of 35 characters'
    ),

    address_3: Yup.string().max(
      35,
      'Address Line 3 can be a maximum of 35 characters'
    ),

    town: Yup.string().max(35, 'Town can be a maximum of 35 characters'),

    postcode: Yup.string()
      .when(['member_type'], {
        is: (member_type) =>
          member_type.includes('Standard Member') ||
          member_type.includes('Service User'),
        then: Yup.string().required('*Postcode is required'),
      })
      .max(12, 'Postcode can be a maximum of 12 characters'),

    directions: Yup.string(),

    phone_number: Yup.string()
      .required('* A phone number is required')
      .max(12, 'Phone number can be a maximum of 12 characters'),

    email_address: Yup.string()
      .email('*Invalid email address format')
      .max(255, 'Email address can be a maximum of 255 characters'),

    next_of_kin_name: Yup.string()
      .when(['member_type'], {
        is: (member_type) => member_type.includes('Standard Member'),
        then: Yup.string().required('* Next Of Kin Name is required'),
      })
      .max(70, 'Next of Kin Name can be a maximum of 70 characters'),

    next_of_kin_relationship: Yup.string().when(['member_type'], {
      is: (member_type) => member_type.includes('Standard Member'),
      then: Yup.string().required('*Next of Kin Relationship is required'),
    }),

    next_of_kin_phone: Yup.string()
      .when(['member_type'], {
        is: (member_type) => member_type.includes('Standard Member'),
        then: Yup.string().required('*Next of Kin Phone Number is required'),
      })
      .max(12, 'Next of Kin Phone can be a maximum of 12 characters'),

    next_of_kin_local: Yup.string().when(['member_type'], {
      is: (member_type) => member_type.includes('Standard Member'),
      then: Yup.string().required(
        '*Whether next of kin lives locally is required'
      ),
    }),

    emergency_contact_2_name: Yup.string().max(
      70,
      'Emergency Contact Name can be a maximum of 70 characters'
    ),

    emergency_contact_2_relationship: Yup.string(),

    emergency_contact_2_phone: Yup.string().max(
      12,
      'Emergency Contact Phone can be a maximum of 12 characters'
    ),

    doctor_name: Yup.string().when(['member_type'], {
      is: (member_type) => member_type.includes('Standard Member'),
      then: Yup.string()
        .required("*Doctor's name is required")
        .max(35, 'Doctor can be a maximum of 35 characters'),
    }),

    surgery_name: Yup.string().when(['member_type'], {
      is: (member_type) => member_type.includes('Standard Member'),
      then: Yup.string()
        .required('*Surgery Name is required')
        .max(35, 'Surgery Name can be a maximum of 35 characters'),
    }),

    doctor_phone: Yup.string().when(['member_type'], {
      is: (member_type) => member_type.includes('Standard Member'),
      then: Yup.string().max(
        12,
        "Doctor's Phone Number can be a maximum of 12 characters"
      ),
    }),

    medications_taken: Yup.string().when(['member_type'], {
      is: (member_type) => member_type.includes('Standard Member'),
      then: Yup.string().required(
        "*Details of medications are required. 'None' is a valid entry"
      ),
    }),

    impaired_hearing: Yup.string().when(['member_type'], {
      is: (member_type) => member_type.includes('Standard Member'),
      then: Yup.string().required(
        '*Hearing impairment information is required'
      ),
    }),

    impaired_vision: Yup.string().when(['member_type'], {
      is: (member_type) => member_type.includes('Standard Member'),
      then: Yup.string().required('*Vision impairment information is required'),
    }),

    impaired_mobility: Yup.string().when(['member_type'], {
      is: (member_type) => member_type.includes('Standard Member'),
      then: Yup.string().required(
        '*Mobility impairment information is required'
      ),
    }),

    fall_risk: Yup.string().when(['member_type'], {
      is: (member_type) => member_type.includes('Standard Member'),
      then: Yup.string().required('*Fall risk information is required'),
    }),

    dementia: Yup.string().when(['member_type'], {
      is: (member_type) => member_type.includes('Standard Member'),
      then: Yup.string().required('*Dementia or similar issues information is required'),
    }),

    impaired_communication: Yup.string(),

    wander_risk: Yup.string(),

    diabetes: Yup.string().when(['member_type'], {
      is: (member_type) => member_type.includes('Standard Member'),
      then: Yup.string().required('*Diabetes information is required'),
    }),

    food_allergies: Yup.string().when(['member_type'], {
      is: (member_type) => member_type.includes('Standard Member'),
      then: Yup.string().required('*Food allergy information is required'),
    }),

    food_allergy_list: Yup.string().when(['food_allergies'], {
      is: (food_allergy_list) => food_allergy_list === 'Yes',
      then: Yup.string().required(
        '*A list of food allergies is needed if the Food Allergies field is set to Yes'
      ),
    }),

    catheter: Yup.string().when(['member_type'], {
      is: (member_type) =>
        member_type !== 'Carer' && member_type !== 'Service User',
      then: Yup.string().required('*Catheter information is required'),
    }),

    medical_equipment: Yup.string(),

    other_medical_issues: Yup.string(),

    interests: Yup.string(),

    topics_to_avoid: Yup.string(),

    blue_badge_holder: Yup.string().max(
      35,
      'Blue Badge Holder can be a maximum of 35 characters'
    ),

    other_info: Yup.string(),

    gdpr_form_signed: Yup.string().required(
      '*GDPR form signed is a required field'
    ),

    gdpr_form_link: Yup.string().when(['gdpr_form_signed'], {
      is: (gdpr_form_signed) => gdpr_form_signed === 'Yes',
      then: Yup.string().required(
        '*A link to the signed GDPR form is required'
      ),
    }),

    cared_for_medical_conditions: Yup.string().when(['member_type'], {
      is: (member_type) => member_type.includes('Carer'),
      then: Yup.string().required(
        '*Cared for medical condition details are required'
      ),
    }),

    is_deceased: Yup.string(),

    deceased_date: Yup.string().when('is_deceased', {
      is: 'Yes',
      then: Yup.string()
        .required('*An approximate deceased date is required')
        .test(
          'valid-date',
          '**The deceased date must be a valid date in the format DD/MM/YYYY',
          (value) => {
            return value !== 'Invalid Date';
          }
        ),
    }),
  });

  // Recoil global state to trigger data table refresh after member edit button is clicked
  const [buttonisClicked, setButtonIsClicked] = useRecoilState(
    memberButtonClickedState
  );

  // State for MUI snackbar popup open status
  const [open, setOpen] = useState(false);

  //State for MUI snackbar popup message
  const [message, setMessage] = useState('');

  //State for MUI snackbar popup severity
  const [severity, setSeverity] = useState();
  // Variable for property to close MUI snackbar popup
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <div className="createMemberPage px-5 relative">
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, formik) => {
            // Check if date_of_birth field has been deleted and changes value from '' to null if it has (otherwise causes MYSQL error/backend crash)
            if (values.date_of_birth === '') {
              values.date_of_birth = null; // Set the value to null
            }
            mutate(values, {
              onSuccess: () => {
                setOpen(true);
                setMessage('Member changes saved!');
                setSeverity('success');
                setButtonIsClicked(buttonisClicked + 1); //updates Recoil global state, to trigger data-table refetch of data
              },
              onError: (response) => {
                setOpen(true);
                setMessage('Error, member changes not changed!');
                setSeverity('error');
                console.log(response);
              },
            });
          }}
        >
          <Form className="formContainer">
            <h1 className="pb-3 text-xl font-semibold">Contact Information</h1>

            <div>
              <CustomSelectField
                name="member_type"
                label="Member Type"
                options={[
                  { label: 'Standard Member', value: 'Standard Member' },
                  { label: 'Carer', value: 'Carer' },
                  { label: 'Service User', value: 'Service User' },
                  {
                    label: 'Standard Member & Carer',
                    value: 'Standard Member & Carer',
                  },
                  {
                    label: 'Standard Member & Service User',
                    value: 'Standard Member & Service User',
                  },
                  {
                    label: 'Standard Member & Carer & Service User',
                    value: 'Standard Member & Carer & Service User',
                  },
                  {
                    label: 'Carer & Service User',
                    value: 'Carer & Service User',
                  },
                ]}
              />
            </div>

            <div>
              <CustomTextField name="forename" label="Forename" />
            </div>

            <div>
              <CustomTextField name="surname" label="Surname" />
            </div>

            <div>
              <CustomTextField name="known_as" label="Likes to be known as" />
            </div>

            <div>
              <CustomDateField name="date_of_birth" label="Date Of Birth" />
            </div>

            <div>
              <CustomSelectField
                name="gender"
                label="Gender"
                options={[
                  { label: 'Male', value: 'Male' },
                  { label: 'Female', value: 'Female' },
                  { label: 'Declined', value: 'Declined' },
                ]}
              />
            </div>

            <div>
              <CustomSelectField
                name="ethnicity"
                label="Ethnicity"
                options={[
                  { label: 'White', value: 'White' },
                  {
                    label: 'Asian/Asian/British',
                    value: 'Asian/Asian/British',
                  },
                  {
                    label: 'Black/Black British',
                    value: 'Black/Black British',
                  },
                  { label: 'Mixed Ethnicity', value: 'Mixed Ethnicity' },
                  { label: 'Other', value: 'Other' },
                  { label: 'Declined', value: 'Declined' },
                ]}
              />
            </div>

            <div>
              <CustomTextField name="address_1" label="Address Line 1" />
            </div>

            <div>
              <CustomTextField name="address_2" label="Address Line 2" />
            </div>

            <div>
              <CustomTextField name="address_3" label="Address Line 3" />
            </div>

            <div>
              <CustomTextField name="town" label="Town" />
            </div>

            <div>
              <CustomTextField name="postcode" label="Postcode" />
            </div>

            <div>
              <CustomTextField name="directions" label="Directions to home" />
            </div>

            <div>
              <CustomTextField name="phone_number" label="Phone number" />
            </div>

            <div>
              <CustomTextField name="email_address" label="Email Address" />
            </div>

            <div>
              <CustomTextField
                name="next_of_kin_name"
                label="Next of kin name"
              />
            </div>

            <div>
              <CustomSelectField
                name="next_of_kin_relationship"
                label="Next of kin relationship"
                options={[
                  { label: 'Spouse', value: 'Spouse' },
                  { label: 'Partner', value: 'Partner' },
                  { label: 'Relative', value: 'Relative' },
                  { label: 'Carer', value: 'Carer' },
                  { label: 'Other', value: 'Other' },
                ]}
              />
            </div>

            <div>
              <CustomTextField
                name="next_of_kin_phone"
                label="Next of kin phone"
              />
            </div>

            <div>
              <CustomSelectField
                name="next_of_kin_local"
                label="Next of kin local"
                options={[
                  { label: 'Yes', value: 'Yes' },
                  { label: 'No', value: 'No' },
                ]}
              />
            </div>

            <div>
              <CustomTextField
                name="emergency_contact_2_name"
                label="2nd emergency contact name"
              />
            </div>

            <div>
              <CustomSelectField
                name="emergency_contact_2_relationship"
                label="2nd emergency contact relationship"
                options={[
                  { label: 'Spouse', value: 'Spouse' },
                  { label: 'Partner', value: 'Partner' },
                  { label: 'Relative', value: 'Relative' },
                  { label: 'Carer', value: 'Carer' },
                  { label: 'Other', value: 'Other' },
                ]}
              />
            </div>

            <div>
              <CustomTextField
                name="emergency_contact_2_phone"
                label="2nd emergency contact phone number"
              />
            </div>

            <h1 className="pb-3 pt-5 text-xl font-semibold">
              Medical Information
            </h1>

            <div>
              <CustomTextField name="doctor_name" label="Doctor's name" />
            </div>

            <div>
              <CustomTextField
                name="surgery_name"
                label="Doctor's surgery name"
              />
            </div>

            <div>
              <CustomTextField
                name="doctor_phone"
                label="Doctor's surgery phone number"
              />
            </div>

            <div>
              <CustomSelectField
                name="impaired_hearing"
                label="Impaired hearing/hearing aid"
                options={[
                  { label: 'Yes', value: 'Yes' },
                  { label: 'No', value: 'No' },
                ]}
              />
            </div>

            <div>
              <CustomSelectField
                name="impaired_vision"
                label="Impaired vision"
                options={[
                  { label: 'Yes', value: 'Yes' },
                  { label: 'No', value: 'No' },
                ]}
              />
            </div>

            <div>
              <CustomSelectField
                name="impaired_mobility"
                label="Impaired mobility"
                options={[
                  { label: 'Yes', value: 'Yes' },
                  { label: 'No', value: 'No' },
                ]}
              />
            </div>

            <div>
              <CustomSelectField
                name="fall_risk"
                label="Fall risk"
                options={[
                  { label: 'Yes', value: 'Yes' },
                  { label: 'No', value: 'No' },
                ]}
              />
            </div>

            <div>
              <CustomSelectField
                name="dementia"
                label="Dementia or similar issues"
                options={[
                  { label: 'Yes', value: 'Yes' },
                  { label: 'No', value: 'No' },
                ]}
              />
            </div>

            <div>
              <CustomSelectField
                name="impaired_communication"
                label="Impaired communication"
                options={[
                  { label: 'Yes', value: 'Yes' },
                  { label: 'No', value: 'No' },
                ]}
              />
            </div>

            <div>
              <CustomSelectField
                name="wander_risk"
                label="Do they tend to wander"
                options={[
                  { label: 'Yes', value: 'Yes' },
                  { label: 'No', value: 'No' },
                ]}
              />
            </div>

            <div>
              <CustomSelectField
                name="diabetes"
                label="Diabetes"
                options={[
                  { label: 'Yes', value: 'Yes' },
                  { label: 'No', value: 'No' },
                ]}
              />
              <div className="pb-2"></div>
            </div>

            <div>
              <CustomSelectField
                name="food_allergies"
                label="Food allergies"
                options={[
                  { label: 'Yes', value: 'Yes' },
                  { label: 'No', value: 'No' },
                ]}
              />
            </div>

            <div>
              <CustomMultiLineTextField
                name="food_allergy_list"
                label="List of food allergies"
                rows="2"
              />
            </div>

            <div>
              <CustomSelectField
                name="catheter"
                label="Catheter"
                options={[
                  { label: 'Yes', value: 'Yes' },
                  { label: 'No', value: 'No' },
                ]}
              />
            </div>

            <div>
              <CustomTextField
                name="medical_equipment"
                label="Medical equipment, e.g. walking stick"
              />
            </div>

            <div>
              <CustomTextField
                name="other_medical_issues"
                label="Other medical issues"
              />
            </div>

            <h1 className="pb-3 pt-5 text-xl font-semibold">
              Other Information
            </h1>

            <div>
              <CustomTextField
                name="interests"
                label="Interests/hobbies, past or present?"
              />
            </div>

            <div>
              <CustomTextField
                name="topics_to_avoid"
                label="Anything that upsets/agitates?"
              />
            </div>

            <div>
              <CustomSelectField
                name="blue_badge_holder"
                label="Blue Badge holder"
                options={[
                  { label: 'Yes', value: 'Yes' },
                  { label: 'No', value: 'No' },
                ]}
              />
            </div>

            <div>
              <CustomTextField
                name="other_info"
                label="Other useful information?"
              />
            </div>

            <div>
              <CustomSelectField
                name="gdpr_form_signed"
                label="GDPR consent form signed"
                options={[
                  { label: 'Yes', value: 'Yes' },
                  { label: 'No', value: 'No' },
                ]}
              />
            </div>

            <div>
              <UploadWidget />
            </div>

            <div>
              <CustomTextField
                name="gdpr_form_link"
                label="GDPR Form Link"
                readOnly={true}
              />
            </div>

            <h1 className="pb-3 pt-5 text-xl font-semibold">
              Carer Specific Information
            </h1>

            <div>
              <CustomTextField
                name="cared_for_medical_conditions"
                label="Cared-for medical condition"
              />
            </div>

            <div>
              <Field
                type="hidden"
                autoComplete="off"
                id="inputCreateMember"
                name="last_updated_by"
                placeholder=" "
              />
            </div>

            <h1 className="pb-3 pt-5 text-xl font-semibold">
              Administrative Information
            </h1>

            <div>
              <CustomSelectField
                name="is_deceased"
                label="Deceased"
                options={[
                  { label: 'Yes', value: 'Yes' },
                  { label: 'No', value: 'No' },
                ]}
              />
            </div>

            <div>
              <CustomDateField name="deceased_date" label="Deceased date" />
            </div>

            <div>
              <Field
                type="hidden"
                autoComplete="off"
                id="inputCreateMember"
                name="last_updated_by"
                placeholder=" "
              />
            </div>

            <div>
              <Field
                type="hidden"
                autoComplete="off"
                id="inputCreateMember"
                name="member_id"
                placeholder=" "
              />
            </div>

            <div className="flex flex-col items-center pt-7">
              <Button variant="contained" size="large" type="submit">
                Save Changes
              </Button>
            </div>

            <div className="pb-20">
              <Snackbar
                open={open}
                autoHideDuration={5000}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                sx={{ position: 'absolute' }}
              >
                <Alert
                  severity={severity}
                  variant="filled"
                  sx={{ width: '100%' }}
                >
                  {message}
                </Alert>
              </Snackbar>
            </div>
          </Form>
        </Formik>
      </div>
    </>
  );
}

export default MembersEdit;
