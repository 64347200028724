import React from 'react';
import { Field } from 'formik';
import { TextField, ThemeProvider, createTheme } from '@mui/material';

const CustomMultiLineTextField = ({ name, label, multiline, rows, readOnly, ...props }) => {
  const theme = createTheme({
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: '8px',
          },
        },
      },
    },
  });

  return (
    <div className="pb-4">
      <Field name={name}>
        {({ field, meta }) => (
          <ThemeProvider theme={theme}>
            <TextField
              {...field}
              {...props}
              label={label}
              multiline
              rows={rows}
              error={Boolean(meta.touched && meta.error)} // Convert meta.error to boolean
              helperText={meta.touched && meta.error}
              sx={{
                width: '100%',
                
                
              }}
              variant="outlined"
              InputProps={{
                readOnly: readOnly,
              }}
            />
          </ThemeProvider>
        )}
      </Field>
    </div>
  );
};

export default CustomMultiLineTextField;