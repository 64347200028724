import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup'; //yup does form validation
import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import { useRecoilState, useRecoilValue } from 'recoil';
import { staffButtonClickedState, userNameState } from '../Atoms/atoms';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import SelectField from '../CustomComponents/SelectField';
import CustomTextField from '../CustomComponents/CustomTextField';

//react-query useMutation code
const useStaffCreateMutation = () => {
  return useMutation((formPayload) => {
    return axios.put(process.env.REACT_APP_BE_URL + '/staff/ById', formPayload);
  });
};

//Main function/custom useMutation Hook - creates Formik form
function StaffEdit(props) {
  const { mutate } = useStaffCreateMutation();
  // console.log(props)

  //Gets logged in username from auth.js
  const userName = useRecoilValue(userNameState);
  //console.log('userName from members edit form= ' + JSON.stringify(userName))

  // //Formik initial values
  const initialValues = {
    full_name: props?.staffData?.full_name ?? '', //The ?? '' part populates the form field with blank if the props contain no data/undefined (e.g. before a row is clicked in the table)
    email_address: props?.staffData?.email_address ?? '', //the two questions marks after props and staffData mean ignore if null
    phone_number: props?.staffData?.phone_number ?? '',
    is_event_volunteer: props?.staffData?.is_event_volunteer ?? '',
    is_driver: props?.staffData?.is_driver ?? '',
    is_employee: props?.staffData?.is_employee ?? '',
    staff_id: props?.staffData?.staff_id ?? '',
    //Hidden Fields
    last_updated_by: userName.user.displayName,
  };

  // Yup field validation
  const validationSchema = Yup.object().shape({
    full_name: Yup.string()
      .required('*Staff Name is required')
      .max(70, 'Staff Name can be a maximum of 70 characters'),
    
      email_address: Yup.string().when(['is_driver'], {
      is: (is_driver) => is_driver === 'Driver',
      then: Yup.string()
        .required('*Email address is required')
        .email('*Invalid email address format')
        .max(255, 'Email address can be a maximum of 255 characters'),
    }),
    
    phone_number: Yup.string()
      .required('*Phone number is required')
      .max(12, 'Phone number can be a maximum of 12 characters'),
    is_event_volunteer: Yup.string().required('*Volunteer status is required'),
    is_driver: Yup.string().required('*Driver status is required'),
    is_employee: Yup.string().required('*Employment status is required'),
  });

  // Recoil global state to trigger data table refresh after staff edit button is clicked
  const [buttonisClicked, setButtonIsClicked] = useRecoilState(
    staffButtonClickedState
  );

  // State for MUI snackbar popup open status
  const [open, setOpen] = useState(false);

  //State for MUI snackbar popup message
  const [message, setMessage] = useState('');

  //State for MUI snackbar popup severity
  const [severity, setSeverity] = useState();

  // Variable for property to close MUI snackbar popup
  const handleClose = (reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <div className="createStaffPage px-5 relative">
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, formik) => {
            mutate(values, {
              onSuccess: () => {
                setOpen(true);
                setMessage('Staff member changes saved!');
                setSeverity('success');
                setButtonIsClicked(buttonisClicked + 1); //updates Recoil global state, to trigger data-table refetch of data
              },
              onError: (response) => {
                setOpen(true);
                setMessage('Error, staff member changes not saved!');
                setSeverity('error');
                console.log(response);
              },
            });
          }}
        >
          <Form className="formContainer">
            <h1 className="pb-3 text-xl font-semibold">General Information</h1>

            <div>
              <CustomTextField name="full_name" label="Full Name" />
            </div>

            <div>
              <CustomTextField name="email_address" label="Email Address" />
            </div>

            <div>
              <CustomTextField name="phone_number" label="Phone Number" />
            </div>

            <div>
              <SelectField
                name="is_event_volunteer"
                label="Event Volunteer"
                options={[
                  { label: 'Volunteer', value: 'Volunteer' },
                  { label: 'Not a Volunteer', value: 'Not a Volunteer' },
                ]}
              />
            </div>

            <div>
              <SelectField
                name="is_driver"
                label="Driver Status"
                options={[
                  { label: 'Driver', value: 'Driver' },
                  { label: 'Not a Driver', value: 'Not a Driver' },
                ]}
              />
            </div>

            <div>
              <SelectField
                name="is_employee"
                label="Employment Status"
                options={[
                  { label: 'Employee', value: 'Employee' },
                  { label: 'Not an Employee', value: 'Not an Employee' },
                ]}
              />
            </div>

            <div>
              <Field
                type="hidden"
                autoComplete="off"
                id="inputCreateMember"
                name="last_updated_by"
                placeholder=" "
              />
            </div>

            <div className="flex flex-col items-center pt-7">
              <Button variant="contained" size="large" type="submit">
                Save Changes
              </Button>
            </div>

            <div className="pb-20">
              <Snackbar
                open={open}
                autoHideDuration={5000}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                sx={{ position: 'absolute' }}
              >
                <Alert
                  severity={severity}
                  variant="filled"
                  sx={{ width: '100%' }}
                >
                  {message}
                </Alert>
              </Snackbar>
            </div>
          </Form>
        </Formik>
      </div>
    </>
  );
}

export default StaffEdit;
