import React, { useState } from 'react'
import { DataGrid, GridToolbarQuickFilter } from '@mui/x-data-grid';
import MembersByID from '../Members/MembersByID'

//Function that adds the Search box above the data grid
function QuickSearchToolbar() {
  return (
      <GridToolbarQuickFilter />
  );
}

const DataTable = ({

  rows,
  columns,
  loading,
  sx,

}) => {

  //state to manage cell click
  const [finalClickInfo, setFinalClickInfo] = useState("");
  const handleOnCellClick = (params) => {setFinalClickInfo(params)}
  //console.log('finalclickinfo = ' + JSON.stringify(finalClickInfo))

  //State to manage pagination - default 10 rows per screen
  const [pageSize, setPageSize] = useState(5);

  return (
      <><div className="w-full">
      <DataGrid
        rows={rows}
        columns={columns}
        loading={loading}
        getRowId={(row) => row.member_id}
        sx={sx}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[5, 10, 20, 100]}
        components={{ Toolbar: QuickSearchToolbar }} // activates the searchbar above the data grid
        onCellClick={handleOnCellClick} />

        {/* Code that rendered props to screen during development 
        {finalClickInfo &&
        `Final clicked id = ${finalClickInfo.id}, 
        Final clicked field = ${finalClickInfo.field}, 
        Final clicked value = ${finalClickInfo.value}`}
        {!finalClickInfo && `Click on a column`} */}
      </div>
      <br></br>
    <MembersByID id={finalClickInfo.id} />
</> 
  )
}

export default DataTable