import React from 'react';
import { useEffect, useState } from 'react';
import DataTableNotificationsComments from '../DataTable/DataTableNotificationsComments';
import axios from 'axios';
import { useRecoilValue } from 'recoil';
import { membersCommentsButtonClickedState } from '../Atoms/atoms';

const columns = [
  {
    field: 'member_id',
    hide: true,
  },

  { field: 'forename', headerName: 'Forename', width: 150 },
  { field: 'surname', headerName: 'Surname', width: 150 },
  {
    field: 'member_comment',
    headerName: 'Comment',
    minWidth: 480,
    width: '100%',
    flex:1,
    renderCell: (params) => (
      <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
        {params.value}
      </div>
    ),
  },
  { field: 'createdAt', headerName: 'Date', width: 115 },
  { field: 'last_updated_by', headerName: 'Created By', width: 150 },
  { field: 'member_comment_id', hide: true },
];

const MembersCommentsTable = () => {
  const [membersComments, setMembersComments] = useState([]);
  // console.log('membersComments = ' + JSON.stringify(membersComments))

  const flag = useRecoilValue(membersCommentsButtonClickedState);
  // console.log(flag)

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BE_URL + '/notifications/')
      .then((res) => res.data)
      .then((data) => setMembersComments(data));
  }, [flag]);

  return (
    <>
      <div>
        <h1 className="text-l font-bold pb-5 pt-5">
          Member Comments added in previous 2 weeks:
        </h1>
        <DataTableNotificationsComments
          rows={membersComments}
          columns={columns}
          getRowHeight={() => 'auto'}
          //loading={!membersComments.length}
          sx={{
            height: '424px',

            '& .MuiDataGrid-columnHeaderTitle': { fontWeight: 700 },
            '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
              py: '8px',
            },
            '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
              py: '15px',
            },
            '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
              py: '22px',
            },
          }}
        />
      </div>
    </>
  );
};

export default MembersCommentsTable;
