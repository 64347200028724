import React, { useState } from 'react';
import MemberEventsCreate from '../components/MemberEvents/MemberEventsCreate';
import MemberEventsTable from '../components/MemberEvents/MemberEventsTable';

function MemberEvents() {
  const [collapsed, setCollapsed] = useState(true);

  return (
    <>
      <div
        className={`bg-[#598DF9] text-orientation-mixed writing-mode-vertical text-gray-100 text-xl font-semibold indent-5 pr-0.5 pl-1.5 pt-10`} //text-orientation-mixed writing-mode-vertical are custom css utilities created in index.css to enable vertical text
      >
        Edit Member Event
      </div>

      <div
        className={`bg-[#fbfbfd] ${
          collapsed ? 'w-full p-5' : 'w-1 overflow-hidden opacity-0'
        } duration-700 whitespace-nowrap`}
      >
        <h1 className="text-xl font-bold pb-5">Member Event Edit Form</h1>
        <MemberEventsTable />
      </div>

      <div
        onClick={() => setCollapsed(!collapsed)}
        className="cursor-pointer bg-gray-600 text-orientation-mixed writing-mode-vertical text-gray-100 text-xl font-semibold indent-5 pr-0.5 pl-1.5 pt-10"
      >
        Create New Member Event
      </div>

      <div
        className={` bg-[#fbfbfd] ${
          collapsed ? 'w-0 overflow-hidden opacity-0' : 'w-full'
        } duration-500 whitespace-nowrap`}
      >
        <h1 className="text-xl font-bold p-5">
          New Member Event Creation Form
        </h1>

        <MemberEventsCreate />
        <p>&nbsp;</p>
      </div>
    </>
  );
}

export default MemberEvents;
