import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup'; //yup does form validation
import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import { useRecoilState, useRecoilValue } from 'recoil';
import { servicesButtonClickedState, userNameState } from '../Atoms/atoms';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import CustomTextField from '../CustomComponents/CustomTextField';

//react-query useMutation code
const useServicesCreateMutation = () => {
  return useMutation(async (formPayload) => {
    return await axios.post(
      process.env.REACT_APP_BE_URL + '/services',
      formPayload
    );
  });
};

//Main function - creates Formik form
function ServicesCreate() {
  const { mutate } = useServicesCreateMutation();

  //Gets logged in username from auth.js
  const userName = useRecoilValue(userNameState);
  //console.log('userName from members edit form= ' + JSON.stringify(userName))

  //Formik initial values (not actually used here)
  const initialValues = {
    service_name: '',
    //Hidden Fields
    last_updated_by: userName.user.displayName,
  };

  // Yup field validation
  const validationSchema = Yup.object().shape({
    service_name: Yup.string()
      .required('*Service Name is required')
      .max(70, 'Service Name can be a maximum of 70 characters'),
  });

  // Recoil global state to trigger data table refresh after event edit button is clicked
  const [buttonisClicked, setButtonIsClicked] = useRecoilState(
    servicesButtonClickedState
  );

  // State for MUI snackbar popup open status
  const [open, setOpen] = useState(false);

  //State for MUI snackbar popup message
  const [message, setMessage] = useState('');

  //State for MUI snackbar popup severity
  const [severity, setSeverity] = useState();

  // Variable for property to close MUI snackbar popup
  const handleClose = (reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <div className="createEventPage px-5 relative">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, formik) => {
          mutate(values, {
            onSuccess: () => {
              setOpen(true);
              setMessage('New event created!');
              setSeverity('success');
              setButtonIsClicked(buttonisClicked + 1); //updates Recoil global state, to trigger data-table refetch of data
              formik.resetForm();
            },
            onError: (response) => {
              setOpen(true);
              setMessage('Error, new event not created!');
              setSeverity('error');
              console.log(response);
            },
          });
        }}
      >
        <Form className="formContainer">
          <h1 className="pb-3 text-xl font-semibold">General Information</h1>

          <div>
            <CustomTextField name="service_name" label="Service Name" />
          </div>

          <div className="pb-2 relative">
            <Field
              type="hidden"
              autoComplete="off"
              id="inputCreateMember"
              name="last_updated_by"
              placeholder=" "
            />
          </div>

          <div className="flex flex-col items-center pt-7">
            <Button variant="contained" size="large" type="submit">
              Create Service
            </Button>
          </div>

          <div className="pb-20">
            <Snackbar
              open={open}
              autoHideDuration={5000}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              sx={{ position: 'absolute' }}
            >
              <Alert
                severity={severity}
                variant="filled"
                sx={{ width: '100%' }}
              >
                {message}
              </Alert>
            </Snackbar>
          </div>
        </Form>
      </Formik>
    </div>
  );
}

export default ServicesCreate;
