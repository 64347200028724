import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup'; //yup does form validation
import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import { useRecoilState, useRecoilValue } from 'recoil';
import { staffButtonClickedState, userNameState } from '../Atoms/atoms';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import SelectField from '../CustomComponents/SelectField';
import CustomTextField from '../CustomComponents/CustomTextField';

//react-query useMutation code
const useStaffCreateMutation = () => {
  return useMutation(async (formPayload) => {
    return await axios.post(
      process.env.REACT_APP_BE_URL + '/staff',
      formPayload
    );
  });
};

//Main function - creates Formik form
function StaffCreate() {
  const { mutate } = useStaffCreateMutation();

  //Gets logged in username from auth.js
  const userName = useRecoilValue(userNameState);
  //console.log('userName from members edit form= ' + JSON.stringify(userName))

  //Formik initial values (not actually used here)
  const initialValues = {
    full_name: '',
    email_address: '',
    phone_number: '',
    is_event_volunteer: '',
    is_driver: '',
    is_employee: '',
    //Hidden Fields
    last_updated_by: userName.user.displayName,
  };

  // Yup field validation
  const validationSchema = Yup.object().shape({
    full_name: Yup.string()
      .required('*Staff Name is required')
      .max(70, 'Staff Name can be a maximum of 70 characters'),

    email_address: Yup.string().when(['is_driver'], {
      is: (is_driver) => is_driver === 'Driver',
      then: Yup.string()
        .required('*Email address is required')
        .email('*Invalid email address format')
        .max(255, 'Email address can be a maximum of 255 characters'),
    }),

    phone_number: Yup.string()
      .required('*Phone number is required')
      .max(12, 'Phone number can be a maximum of 12 characters'),
    is_event_volunteer: Yup.string().required('*Volunteer status is required'),
    is_driver: Yup.string().required('*Driver status is required'),
    is_employee: Yup.string().required('*Employment status is required'),
  });

  // Recoil global state to trigger data table refresh after staff edit button is clicked
  const [buttonisClicked, setButtonIsClicked] = useRecoilState(
    staffButtonClickedState
  );

  // State for MUI snackbar popup open status
  const [open, setOpen] = useState(false);

  //State for MUI snackbar popup message
  const [message, setMessage] = useState('');

  //State for MUI snackbar popup severity
  const [severity, setSeverity] = useState();

  // Variable for property to close MUI snackbar popup
  const handleClose = (staff, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <div className="createStaffPage px-5 relative">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, formik) => {
          mutate(values, {
            onSuccess: () => {
              setOpen(true);
              setMessage('New staff member created!');
              setSeverity('success');
              setButtonIsClicked(buttonisClicked + 1); //updates Recoil global state, to trigger data-table refetch of data
              formik.resetForm();
              //console.log(values)
            },
            onError: (response) => {
              setOpen(true);
              setMessage('Error, new staff member not created!');
              setSeverity('error');
              console.log(response);
            },
          });
        }}
      >
        <Form className="formContainer">
          <h1 className="pb-3 text-xl font-semibold">General Information</h1>

          <div>
            <CustomTextField name="full_name" label="Full Name" />
          </div>

          <div>
            <CustomTextField name="email_address" label="Email Address" />
          </div>

          <div>
            <CustomTextField name="phone_number" label="Phone Number" />
          </div>

          <div>
            <SelectField
              name="is_event_volunteer"
              label="Event Volunteer"
              options={[
                { label: 'Volunteer', value: 'Volunteer' },
                { label: 'Not a Volunteer', value: 'Not a Volunteer' },
              ]}
            />
          </div>

          <div>
            <SelectField
              name="is_driver"
              label="Driver Status"
              options={[
                { label: 'Driver', value: 'Driver' },
                { label: 'Not a Driver', value: 'Not a Driver' },
              ]}
            />
          </div>

          <div>
            <SelectField
              name="is_employee"
              label="Employment Status"
              options={[
                { label: 'Employee', value: 'Employee' },
                { label: 'Not an Employee', value: 'Not an Employee' },
              ]}
            />
          </div>

          <div>
            <Field
              type="hidden"
              autoComplete="off"
              id="inputCreateMember"
              name="last_updated_by"
              placeholder=" "
            />
          </div>

          <div className="flex flex-col items-center pt-7">
            <Button variant="contained" size="large" type="submit">
              Create Staff Member
            </Button>
          </div>

          <div className="pb-20">
            <Snackbar
              open={open}
              autoHideDuration={5000}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              sx={{ position: 'absolute' }}
            >
              <Alert
                severity={severity}
                variant="filled"
                sx={{ width: '100%' }}
              >
                {message}
              </Alert>
            </Snackbar>
          </div>
        </Form>
      </Formik>
    </div>
  );
}

export default StaffCreate;
