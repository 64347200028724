
import Button from '@mui/material/Button';

const Logout = () => {
  const googleOut = () => {
    window.open(process.env.REACT_APP_BE_URL+'/auth/logout', '_self');
  };

  return (
    <>
      <div className="flex flex-col h-screen items-center w-full pt-80">
        <h1 className="text-3xl font-bold">Click Below to Logout:</h1>
        
          <div className="pt-20">
            <Button
              variant="contained"
              size="large"
              type="submit"
              onClick={googleOut}
            >
              Logout
            </Button>
          </div>

      </div>
    </>
  );
};

export default Logout;
