
import Button from '@mui/material/Button';

const Login = () => {
  const google = () => {
    window.open(process.env.REACT_APP_BE_URL+'/auth/google', '_self');
  };

  return (
    <>
      <div className="flex flex-col h-screen items-center w-full pt-80">
        <h1 className="text-3xl font-bold">Please Login Below:</h1>
          <div className="pt-20">
            <Button
              variant="contained"
              size="large"
              type="submit"
              onClick={google}
            >
              Login
            </Button>
          </div>
      </div>
    </>
  );
};

export default Login;
