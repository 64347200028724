import React, { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import axios from 'axios';
import * as Yup from 'yup';
import { useMutation } from '@tanstack/react-query';
import { useRecoilState, useRecoilValue } from 'recoil';
import { memberServicesButtonClickedState } from '../Atoms/atoms';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { userNameState } from '../Atoms/atoms';
import MemberDependentField from '../CustomComponents/MemberDependentField';
import StaffDependentField from '../CustomComponents/StaffDependentField';
import emailjs from '@emailjs/browser';
import CustomSelectFieldSaveById from '../CustomComponents/CustomSelectFieldSaveById';
import CustomSelectField from '../CustomComponents/CustomSelectField';
import CustomDateFieldEdit from '../CustomComponents/CustomDateFieldEdit';
import CustomTextField from '../CustomComponents/CustomTextField';
import CustomCurrencyField from '../CustomComponents/CustomCurrencyField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import InputAdornment from '@mui/material/InputAdornment';
import CustomTimeFieldEdit from '../CustomComponents/CustomTimeFieldEdit';
import CustomMultiLineTextField from '../CustomComponents/CustomMultiLineTextField';

//react-query useMutation code to amend existing record in db
const useMemberServicesEditMutation = () => {
  return useMutation(async (formPayload) => {
    return await axios.put(
      process.env.REACT_APP_BE_URL + '/memberservices/ById',
      formPayload
    );
  });
};

//Main function - creates Formik form
function MemberServicesEdit(props) {
  const { mutate } = useMemberServicesEditMutation();
  //console.log('props =', props);

  const [service, setService] = useState([]);
  //console.log('service =', service)

  // Gets array of service ids/names from services table
  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BE_URL + '/memberservices/servicedropdown')
      .then((res) => res.data)
      .then((data) => setService(data));
  }, []);

  //Gets logged in username from auth.js
  const userName = useRecoilValue(userNameState);
  //console.log('userName from members edit form= ' + JSON.stringify(userName))

  //Initial values - populates form fields
  const initialValues = {
    member_id: JSON.stringify(props?.memberServiceData?.member_id) ?? '',
    service_id: JSON.stringify(props?.memberServiceData?.service_id) ?? '',
    service_date: props?.memberServiceData?.service_date ?? '',
    staff_id: JSON.stringify(props?.memberServiceData?.staff_id) ?? '',
    email_address: '',
    date_created: props?.memberServiceData?.date_created ?? '',
    // the below formats returned time as hh:mm and handles the scenario where the value returned from the db is 00:00:00 so nothing is displayed in the field in that case
    appointment_time:
      props?.memberServiceData?.appointment_time === '00:00:00' ||
      !props?.memberServiceData?.appointment_time
        ? ''
        : props?.memberServiceData?.appointment_time.substring(0, 5) ?? '',
    appointment_location: props?.memberServiceData?.appointment_location ?? '',
    cost_of_transport: props?.memberServiceData?.cost_of_transport ?? '',
    escort: props?.memberServiceData?.escort ?? '',
    request_details: props?.memberServiceData?.request_details ?? '',
    address_1: '',
    address_2: '',
    address_3: '',
    town: '',
    postcode: '',
    phone_number: '',
    directions: '',
    next_of_kin_name: '',
    next_of_kin_phone: '',
    medical_equipment: '',
    blue_badge_holder: '',
    request_cancelled: props?.memberServiceData?.request_cancelled ?? '',
    previously_updated_by: props?.memberServiceData?.last_updated_by ?? '', //field to show on screen who was the last person to save this document - read from database

    //Hidden Fields
    last_updated_by: userName.user.displayName  ?? '', //field to update the last_updated_by column in database with the current user's name
    member_service_id: JSON.stringify(props?.memberServiceData?.member_service_id) ?? '',
  };

  // Yup field validation
  const validationSchema = Yup.object().shape({
    member_id: Yup.number()
      .required('*Member Name is required')
      .typeError('*You must select a member'),
    service_id: Yup.string().required('*Service Name is required'),
    service_date: Yup.date()
      .required('*Service Date is required')
      .typeError(
        '*Service Date must be a valid date, and in the format YYYY/MM/DD'
      ),
    staff_id: Yup.string()
      .required('*Driver Name is required')
      .typeError('*You must select a driver'),
    cost_of_transport: Yup.number().typeError(
      '*Cost of transport must be a number'
    ),
  });

  // Recoil global state to trigger data table refresh after event edit button is clicked
  const [buttonIsClicked, setButtonIsClicked] = useRecoilState(
    memberServicesButtonClickedState
  );

  // State for member data to populate member Name dropdown
  const [member, setMember] = useState([]);
  //console.log('member', member)

  // Gets array of member ids/names from member table
  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BE_URL + '/memberservices/memberdropdown')
      .then((res) => res.data)
      .then((data) => setMember(data));
  }, []);

  // State for staff data to populate staff Name dropdown
  const [staff, setStaff] = useState([]);
  //console.log('staff =', staff)

  // Gets array of staff ids/names from staff table
  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BE_URL + '/staffdropdown')
      .then((res) => res.data)
      .then((data) => setStaff(data));
  }, []);

  // State for MUI snackbar popup open status
  const [open, setOpen] = useState(false);

  //State for MUI snackbar popup message
  const [message, setMessage] = useState('');

  //State for MUI snackbar popup severity
  const [severity, setSeverity] = useState();

  // Variable for property to close MUI snackbar popup
  const handleClose = (reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  // List of data to populate select fields, formatted as needed by custom MUI select component
  const formattedMembers = member.map((member) => ({
    label: `${member.forename} ${member.surname}`,
    key: `${member.member_id}`,
    value: `${member.forename} ${member.surname}`,
  }));
  //console.log('formatted members =' + JSON.stringify(formattedMembers))

  // List of data to populate select fields, formatted as needed by custom MUI select component
  const formattedStaff = staff.map((staff) => ({
    label: `${staff.full_name}`,
    key: `${staff.staff_id}`,
    value: `${staff.full_name}`,
  }));
  //console.log('formatted staff =' + JSON.stringify(formattedStaff))

  // List of data to populate select fields, formatted as needed by custom MUI select component
  const formattedServices = service.map((service) => ({
    label: `${service.service_name}`,
    key: `${service.service_id}`,
    value: `${service.service_name}`,
  }));
  //console.log('formatted service =' + JSON.stringify(formattedServices))

  //function to send email to driver
  function SendEmail(values) {
    //Following variables determine the name values based on the associated ids selected in the Formik form
    const memberEmail = formattedMembers.find(
      (member) => member.key === values.member_id
    );
    const memberEmailName = memberEmail ? memberEmail.value : '';

    const serviceEmail = formattedServices.find(
      (service) => service.key === values.service_id
    );
    const serviceEmailName = serviceEmail ? serviceEmail.value : '';

    const staffEmail = formattedStaff.find(
      (staff) => staff.key === values.staff_id
    );
    const staffEmailName = staffEmail ? staffEmail.value : '';

    //The following determines whether the email template is the medical transport or prescription collection

    let requestTemplate = null;

    if (values.service_id === '1') {
      requestTemplate = process.env.REACT_APP_EMAILJS_MED_TRANS_TEMPLATE_ID;
    } else {
      requestTemplate = process.env.REACT_APP_EMAILJS_PRESC_COLL_TEMPLATE_ID;
    }

    //The following sends data elements identified below to emailJS
    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        requestTemplate,
        {
          member_id: memberEmailName,
          service_id: serviceEmailName,
          service_date: new Date(values.service_date).toLocaleDateString(
            'en-GB'
          ), // Format the service date as dd/mm/yyyy
          staff_id: staffEmailName,
          email_address: values.email_address,
          date_created: values.date_created,
          appointment_time: values.appointment_time,
          appointment_location: values.appointment_location,
          cost_of_transport: values.cost_of_transport,
          escort: values.escort,
          request_details: values.request_details,
          address_1: values.address_1,
          address_2: values.address_2,
          address_3: values.address_3,
          town: values.town,
          postcode: values.postcode,
          phone_number: values.phone_number,
          directions: values.directions,
          next_of_kin_name: values.next_of_kin_name,
          next_of_kin_phone: values.next_of_kin_phone,
          medical_equipment: values.medical_equipment,
          blue_badge_holder: values.blue_badge_holder,
          request_cancelled: values.request_cancelled,
          last_updated_by: values.last_updated_by,
          sent_from: userName.user.emails[0].value, //email address taken from Google logon res
        },
        process.env.REACT_APP_EMAILJS_PUBLIC_ID
      )
      .then(
        (result) => {
          //console.log(result.text, values);
        },
        (error) => {
          //console.log(error.text);
        }
      );
  }

  return (
    <>
      <div className="createMemberEventPage px-5 relative">
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, formik) => {
            if (document.activeElement.dataset.flag === 'action-2') {
              SendEmail(values);
              mutate(values, {
                onSuccess: () => {
                  setOpen(true);
                  setMessage(
                    'Member-service saved & created and driver emailed!'
                  );
                  setSeverity('success');
                  setButtonIsClicked(buttonIsClicked + 1); // updates Recoil global state to trigger data-table refetch of data
                  //formik.resetForm();
                  // console.log('values=' + values);
                },
                onError: (response) => {
                  setOpen(true);
                  setMessage(
                    'Error, member-service not saved and/or email failed!'
                  );
                  setSeverity('error');
                  console.log(response);
                  console.log('values=' + JSON.stringify(values));
                },
              });
            } else {
              mutate(values, {
                onSuccess: () => {
                  setOpen(true);
                  setMessage('Member-service saved!');
                  setSeverity('success');
                  setButtonIsClicked(buttonIsClicked + 1); // updates Recoil global state to trigger data-table refetch of data
                  //formik.resetForm();
                  // console.log('values=' + values);
                },
                onError: (response) => {
                  setOpen(true);
                  setMessage('Error, member-service not saved!');
                  setSeverity('error');
                  console.log(response);
                  console.log('values=' + JSON.stringify(values));
                },
              });
            }
          }}
        >
          <Form className="formContainer">
            <h1 className="pb-3 text-xl font-semibold">General Information</h1>

            <div>
              <CustomSelectFieldSaveById
                name="service_id"
                label="Service Name"
                options={formattedServices}
              />
            </div>

            <div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <CustomDateFieldEdit name="service_date" label="Service Date" />
              </LocalizationProvider>
            </div>

            <div>
              <CustomSelectFieldSaveById
                name="staff_id"
                label="Driver name"
                options={formattedStaff}
              />
            </div>

            <div>
              <StaffDependentField
                type="hidden"
                autoComplete="off"
                name="email_address"
                placeholder=" "
                readOnly={true}
              />
            </div>

            <div>
              <CustomSelectFieldSaveById
                name="member_id"
                label="Member name"
                options={formattedMembers}
              />
            </div>

            <div>
              <MemberDependentField
                name="address_1"
                readOnly={true}
                label="Address line 1"
              />
            </div>

            <div>
              <MemberDependentField
                name="address_2"
                readOnly={true}
                label="Address line 2"
              />
            </div>

            <div>
              <MemberDependentField
                name="address_3"
                readOnly={true}
                label="Address line 3"
              />
            </div>

            <div>
              <MemberDependentField name="town" readOnly={true} label="Town" />
            </div>

            <div>
              <MemberDependentField
                name="postcode"
                readOnly={true}
                label="Postcode"
              />
            </div>

            <div>
              <MemberDependentField
                name="phone_number"
                readOnly={true}
                label="Phone number"
              />
            </div>

            <div>
              <MemberDependentField
                name="directions"
                readOnly={true}
                label="Directions to home"
              />
            </div>

            <div>
              <MemberDependentField
                name="next_of_kin_name"
                readOnly={true}
                label="Emergency contact name"
              />
            </div>

            <div className="pb-2 relative">
              <MemberDependentField
                name="next_of_kin_phone"
                readOnly={true}
                label="Emergency contact phone"
              />
            </div>

            <div>
              <MemberDependentField
                name="medical_equipment"
                readOnly={true}
                label="Medical equipment"
              />
            </div>

            <div>
              <MemberDependentField
                name="blue_badge_holder"
                readOnly={true}
                label="Blue badge"
              />
            </div>

            <h1 className="pb-3 pt-5 text-xl font-semibold">
              Medical Transport Information
            </h1>

            <div>
              <CustomTextField name="escort" label="Escort name" />
            </div>

            <div>
              <CustomTimeFieldEdit
                name="appointment_time"
                label="Appointment Time"
              />
            </div>

            <div>
              <CustomTextField
                name="appointment_location"
                label="Appointment location"
              />
            </div>

            <div>
              <CustomCurrencyField
                name="cost_of_transport"
                label="Cost of transport"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">£</InputAdornment>
                  ),
                }}
              />
            </div>

            <h1 className="pb-3 pt-5 text-xl font-semibold">Request Details</h1>

            <div>
              <CustomMultiLineTextField
                name="request_details"
                label="Request details"
                rows="5"
              />
            </div>

            <h1 className="pb-3 pt-5 text-xl font-semibold">
              Administrative Information
            </h1>

            <div>
              <CustomTextField
                name="previously_updated_by"
                label="Last Updated by"
                readOnly={true}
              />
            </div>        

            <div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <CustomDateFieldEdit
                  name="date_created"
                  label="Request created date"
                  readOnly={true}
                />
              </LocalizationProvider>
            </div>

            <div>
              <CustomSelectField
                name="request_cancelled"
                label="Request cancelled"
                options={[
                  { label: 'Cancelled', value: 'Cancelled' },
                  { label: 'Not cancelled', value: 'Not Cancelled' },
                ]}
              />
            </div>

            <div>
            <Field
              type="hidden"
              id="last_updated_by"
              name="last_updated_by"
              readOnly={true}
            />
          </div>

          <div>
              <Field
                type="hidden"
                id="member_service_id"
                label="member_service_id"
                readOnly={true}
              />
            </div>


            <div className="flex flex-col items-center pt-7">
              <Button
                variant="contained"
                size="large"
                type="submit"
                data-flag="action-1"
              >
                Save Member-Service only
              </Button>
            </div>
            <div className="flex flex-col items-center pt-7">
              <Button
                variant="contained"
                size="large"
                type="submit"
                data-flag="action-2"
              >
                Save Member-Service & email driver
              </Button>
            </div>

            <div className="pb-20">
              <Snackbar
                open={open}
                autoHideDuration={5000}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                sx={{ position: 'absolute' }}
              >
                <Alert
                  severity={severity}
                  variant="filled"
                  sx={{ width: '100%' }}
                >
                  {message}
                </Alert>
              </Snackbar>
            </div>
          </Form>
        </Formik>
      </div>
    </>
  );
}

export default MemberServicesEdit;
