import React from 'react';
import { useEffect, useState } from 'react';
import DataTableNotificationsBirthdays from '../DataTable/DataTableNotificationsBirthdays';
import axios from 'axios';
import { useRecoilValue } from 'recoil';
import { membersBirthdaysButtonClickedState } from '../Atoms/atoms';

const columns = [
  {
    field: 'member_id',
    hide: true,
  },
  { field: 'forename', headerName: 'Forename', width: 150 },
  { field: 'surname', headerName: 'Surname', width: 150 },
  { field: 'date_of_birth', headerName: 'Date of Birth', width: 150 },
];

const MembersBirthdaysTable = () => {
  const [membersBirthdays, setMembersBirthdays] = useState([]);
  // console.log('membersBirthdays = ' + JSON.stringify(membersBirthdays))



  
  const flag = useRecoilValue(membersBirthdaysButtonClickedState);
  // console.log(flag)

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BE_URL + '/notifications/birthdays')
      .then((res) => res.data)
      .then((data) => setMembersBirthdays(data));
  }, [flag]);

  if (membersBirthdays.length === 0) {
    return (
      <h1 className="text-l font-bold pb-5 pt-5">
      Notification: There are no member birthdays upcoming in next 2 weeks!
    </h1>
    )
  }


  return (
    <>
      <div>
        <h1 className="text-l font-bold pb-5 pt-5">
          Member birthdays upcoming in next 2 weeks:
        </h1>
        <DataTableNotificationsBirthdays
          rows={membersBirthdays}
          columns={columns}
          loading={!membersBirthdays.length}
          sx={{
            height: '424px',

            '& .MuiDataGrid-columnHeaderTitle': { fontWeight: 700 },
            '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
              py: '8px',
            },
            '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
              py: '15px',
            },
            '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
              py: '22px',
            },
          }}
        />
      </div>
    </>
  );
};

export default MembersBirthdaysTable;
