import React, { useEffect, useState } from 'react'
import axios from 'axios'
import MemberEventsEdit from '../MemberEvents/MemberEventsEdit'


function MemberEventsByID(props) {
  
  const [memberEventData, setMemberEventData] = useState([])
  useEffect(() => {
    axios.get(process.env.REACT_APP_BE_URL+`/memberevents/byId/${props.id}`)
    .then((response) => {
      setMemberEventData(response.data);
      // console.log('props = ' + JSON.stringify(memberEventData))
    })
  }, [props.id])

      //Conditional rendering - only displays the edit form if a row has been clicked in the table.  
    //Prevents user filling in the form without clicking a row, which would crash node due to lack of event_id 
    if (props.id === undefined) 
     {return}
    else {  
      return (
          //next line takes props from DataTable.jsx - where the prop = id, which in turn equals the 'row id' clicked in the table, which contains 'member_event_id'
          <>
          <MemberEventsEdit memberEventData={memberEventData}/></>
      )}
}

export default MemberEventsByID
