import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup'; //yup does form validation
import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import { useRecoilState, useRecoilValue } from 'recoil';
import { userSettingsButtonClickedState, userNameState } from '../Atoms/atoms';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import SelectField from '../CustomComponents/SelectField';

//react-query useMutation code
const useUserSettingsCreateMutation = () => {
  return useMutation(async (formPayload) => {
    return await axios.post(
      process.env.REACT_APP_BE_URL + '/usersettings',
      formPayload
    );
  });
};

//Variable to store Tailwind css for 'Field' elements of Formik function
const formikField =
  'my-px block px-2.5 pb-2.5 pt-4 w-full text-[17px] text-gray-900 bg-transparent rounded-lg border border-gray-400 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer focus:border-2';

//Variable to store Tailwind css for 'Label' elements of Formik function
const formikLabel =
  'absolute text-base text-gray-500 duration-300 transform -translate-y-5 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-6 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-4 left-2';

//Main function - creates Formik form
function UserSettingsCreate() {
  const { mutate } = useUserSettingsCreateMutation();

  //Gets logged in username from auth.js
  const userName = useRecoilValue(userNameState);
  //console.log('userName from members edit form= ' + JSON.stringify(userName))

  //Formik initial values (not actually used here)
  const initialValues = {
    username: '',
    google_id: '',
    is_sys_admin: '',
    is_member_maint_user: '',
    is_services_maint_user: '',
  //Hidden Fields
  last_updated_by: userName.user.displayName,
  };

  // Yup field validation
  const validationSchema = Yup.object().shape({
    username: Yup.string()
      .required('*Full Name is required')
      .max(30, 'Full Name can be a maximum of 30 characters'),
    google_id: Yup.string()
      .required('*Google ID Number is required')
      .max(30, 'Google ID Number can be a maximum of 30 characters'),
    is_sys_admin: Yup.string().required('*This field is required'),
    is_member_maint_user: Yup.string().required('*This field is required'),
    is_services_maint_user: Yup.string().required('*This field is required'),
  });

  // Recoil global state to trigger data table refresh after User Settings edit button is clicked
  const [buttonisClicked, setButtonIsClicked] = useRecoilState(
    userSettingsButtonClickedState
  );

  // State for MUI snackbar popup open status
  const [open, setOpen] = useState(false);

  //State for MUI snackbar popup message
  const [message, setMessage] = useState('');

  //State for MUI snackbar popup severity
  const [severity, setSeverity] = useState();

  // Variable for property to close MUI snackbar popup
  const handleClose = (reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <div className="createUserSettingsPage px-5 relative">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, formik) => {
          mutate(values, {
            onSuccess: () => {
              setOpen(true);
              setMessage('New user created!');
              setSeverity('success');
              setButtonIsClicked(buttonisClicked + 1); //updates Recoil global state, to trigger data-table refetch of data
              formik.resetForm();
            },
            onError: (response) => {
              setOpen(true);
              setMessage('Error, new user not created!');
              setSeverity('error');
              console.log(response);
            },
          });
        }}
      >
        <Form className="formContainer">
          <h1 className="pb-3 text-xl font-semibold">General Information</h1>
          <div className="pb-2 relative">
            <Field
              className={formikField}
              autoComplete="off"
              id="inputCreateStaff"
              name="username"
              placeholder=" "
            />
            <label className={formikLabel}>Full Name</label>
            <ErrorMessage
              name="username"
              component="span"
              className="text-red-600"
            />
          </div>

          <div className="pb-2 relative">
            <Field
              className={formikField}
              autoComplete="off"
              id="inputCreateStaff"
              name="google_id"
              placeholder=" "
            />
            <label className={formikLabel}>Google ID Number</label>
            <ErrorMessage
              name="google_id"
              component="span"
              className="text-red-600"
            />
          </div>

          <h1 className="pb-3 pt-5 text-xl font-semibold">
            System Authorisations
          </h1>

          <div>
            <SelectField
              name="is_sys_admin"
              label="Can maintain database users"
              options={[
                { label: 'Yes', value: 'Yes' },
                { label: 'No', value: 'No' },
              ]}
            />
            <div className="pb-2">
              <ErrorMessage
                name="is_sys_admin"
                component="span"
                className="text-red-600"
              />
            </div>
          </div>

          <div>
            <SelectField
              name="is_member_maint_user"
              label="Can maintain members, member comments & member-events"
              options={[
                { label: 'Yes', value: 'Yes' },
                { label: 'No', value: 'No' },
              ]}
            />
            <div className="pb-2">
              <ErrorMessage
                name="is_member_maint_user"
                component="span"
                className="text-red-600"
              />
            </div>
          </div>

          <div>
            <SelectField
              name="is_services_maint_user"
              label="Can maintain services & member-services"
              options={[
                { label: 'Yes', value: 'Yes' },
                { label: 'No', value: 'No' },
              ]}
            />
            <div className="pb-2">
              <ErrorMessage
                name="is_services_maint_user"
                component="span"
                className="text-red-600"
              />
            </div>
          </div>

          <div className="pb-2 relative">
            <Field
              type="hidden"
              className={formikField}
              autoComplete="off"
              id="inputCreateMember"
              name="last_updated_by"
              placeholder=" "
            />
            <label className={formikLabel}></label>
            <ErrorMessage
              name="last_updated_by"
              component="span"
              className="text-red-600"
            />
          </div>

          <div className="flex flex-col items-center pt-7">
            <Button variant="contained" size="large" type="submit">
              Create User
            </Button>
          </div>
          <br></br>
          <br></br>
          <br></br>
          <br></br>

          <Snackbar
            open={open}
            autoHideDuration={5000}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            sx={{ position: 'absolute' }}
          >
            <Alert severity={severity} variant="filled" sx={{ width: '100%' }}>
              {message}
            </Alert>
          </Snackbar>
        </Form>
      </Formik>
    </div>
  );
}

export default UserSettingsCreate;
