import Navbar from './components/Navbar';
import Notifications from './pages/Notifications';
import Members from './pages/Members';
import Events from './pages/Events';
import MemberEvents from './pages/MemberEvents';
import MemberServices from './pages/MemberServices';
import Staff from './pages/Staff';
import Services from './pages/Services';
import UserSettings from './pages/UserSettings';
import Login from './pages/Login';
import LoginSuccess from './pages/LoginSuccess';
import LoginFailed from './pages/LoginFailed';
import Logout from './pages/Logout';
import LogoutSuccess from './pages/LogoutSuccess';
import MembersComments from './pages/MembersComments';
import PageNotFound from './pages/PageNotFound';
import { Route, Routes } from 'react-router-dom'; // allows definition of all routes and to group them together
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { userNameState } from './components/Atoms/atoms';
import { useRecoilState } from 'recoil';

function App() {
  const client = new QueryClient();

  const [user, setUser] = useState(null);
  const [validatedUser, setValidatedUser] = useState(false);
  const [isUserValidated, setIsUserValidated] = useState(false); // Flag to track if the user has been validated

  // eslint-disable-next-line no-unused-vars
  const [userNameConst, setUserNameConst] = useRecoilState(userNameState);

  useEffect(() => {
    const getUser = () => {
      fetch(process.env.REACT_APP_BE_URL + '/auth/login/success', {
        method: 'GET',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': true,
        },
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          throw new Error('Authentication failed');
        })
        .then((resObject) => {
          setUser(resObject.user.id);
          setUserNameConst(resObject);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    const fetchData = () => {
      axios
        .get(process.env.REACT_APP_BE_URL + `/auth/user/${user}`)
        .then((res) => res.data)
        .then((data) => {
          if (data.length > 0 && !isUserValidated) {
            setIsUserValidated(true); // Set the flag to true
            setValidatedUser(true);
          }
        });
    };

    getUser();
    fetchData();
  }, [user, setUserNameConst, isUserValidated]);

  console.log('Authorised User =' + validatedUser);

  return (
    <>
      <div className="flex">
        <QueryClientProvider client={client}>
          <Navbar />
          <Routes>
            <Route path="/" element={<Login />} />
            <Route
              path="/notifications"
              element={isUserValidated ? <Notifications /> : <Login />}
            />
            <Route
              path="/members"
              element={isUserValidated ? <Members /> : <Login />}
            />
            <Route
              path="/events"
              element={isUserValidated ? <Events /> : <Login />}
            />
            <Route
              path="/memberevents"
              element={isUserValidated ? <MemberEvents /> : <Login />}
            />
            <Route
              path="/staff"
              element={isUserValidated ? <Staff /> : <Login />}
            />
            <Route
              path="/services"
              element={isUserValidated ? <Services /> : <Login />}
            />
            <Route
              path="/memberservices"
              element={
                isUserValidated ? <MemberServices /> : <Login />
              }
            />
            <Route
              path="/usersettings"
              element={isUserValidated ? <UserSettings /> : <Login />}
            />
            <Route path="/login/success" element={<LoginSuccess />} />
            <Route path="/login/failed" element={<LoginFailed />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/logout/success" element={<LogoutSuccess />} />
            <Route
              path="/memberscomments"
              element={
                isUserValidated ? <MembersComments /> : <Login />
              }
            />
            <Route
              path="/*"
              element={isUserValidated ?  <PageNotFound /> : <Login />}
            />
          </Routes>
        </QueryClientProvider>
      </div>
    </>
  );
}
export default App;
