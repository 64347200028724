import React, { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import { useRecoilState, useRecoilValue } from 'recoil';
import { memberEventButtonClickedState } from '../Atoms/atoms';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { userNameState } from '../Atoms/atoms';
import CustomMultipleSelectField from '../CustomComponents/CustomMultipleSelectField';
import CustomSelectFieldSaveById from '../CustomComponents/CustomSelectFieldSaveById';
import CustomDateField from '../CustomComponents/CustomDateField';

//React-Query function to post data to member_events table
const useMemberEventsCreateMutationME = () => {
  return useMutation(async (formPayload) => {
    return await axios.post(
      process.env.REACT_APP_BE_URL + '/memberevents/mememberevents',
      formPayload
    );
    //console.log('payload_memberevents field = ' + JSON.stringify(formPayload))
  });
};

//React-Query function to post data to staff_events table
const useMemberEventsCreateMutationSE = () => {
  return useMutation(async (formPayload) => {
    return await axios.post(
      process.env.REACT_APP_BE_URL + '/memberevents/mestaffevents',
      formPayload
    );
    // console.log('payload_staffevents field = ' + JSON.stringify(formPayload))
  });
};

//Main function - creates Formik form
function MemberEventsCreate() {
  const { mutate: memberEventMutate } = useMemberEventsCreateMutationME();
  const { mutate: staffEventMutate } = useMemberEventsCreateMutationSE();

  //Gets logged in username from auth.js
  const userName = useRecoilValue(userNameState);
  //console.log('userName from members edit form= ' + JSON.stringify(userName))

  //Formik initial values.  Setting the two multiple select fields as arrays gets rid of a warning message in console
  const initialValues = {
    member_id: [],
    event_id: '',
    event_date: '',
    staff_id: [],

    //Hidden Fields
    last_updated_by: userName.user.displayName,
  };

  // Yup field validation
  const validationSchema = Yup.object().shape({
    member_id: Yup.number()
      .required('*Member Name is required')
      .typeError('*You must select one or more members'),
    event_id: Yup.string().required('*Event Name is required'),
    event_date: Yup.date()
      .required('*Event Date is required')
      .typeError(
        '*Event Date must be a valid date, and in the format YYYY/MM/DD'
      ),
    staff_id: Yup.number()
      .required('*Staff Name is required')
      .typeError('*You must select one or more staff / volunteers'),
  });

  // Recoil global state to trigger data table refresh after event edit button is clicked
  const [buttonIsClicked, setButtonIsClicked] = useRecoilState(
    memberEventButtonClickedState
  );

  // State for event data to populate Event Name dropdown
  const [event, setEvent] = useState([]);
  //  console.log(event)

  // Gets array of event ids/names from event table
  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BE_URL + '/eventdropdown')
      .then((res) => res.data)
      .then((data) => setEvent(data));
  }, []);

  // State for member data to populate member Name dropdown
  const [member, setMember] = useState([]);
  //console.log(member)

  // Gets array of member ids/names from member table
  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BE_URL + '/memberevents/memberdropdown')
      .then((res) => res.data)
      .then((data) => setMember(data));
  }, []);

  // State for staff data to populate staff Name dropdown
  const [staff, setStaff] = useState([]);
  //console.log(staff)

  // Gets array of staff ids/names from staff table
  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BE_URL + '/staffdropdown')
      .then((res) => res.data)
      .then((data) => setStaff(data));
  }, []);

  // State for MUI snackbar popup open status
  const [open, setOpen] = useState(false);

  //State for MUI snackbar popup message
  const [message, setMessage] = useState('');

  //State for MUI snackbar popup severity
  const [severity, setSeverity] = useState();

  // Variable for property to close MUI snackbar popup
  const handleClose = (reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  // List of data to populate select fields, formatted as needed by custom MUI select component
  const formattedMembers = member.map((member) => ({
    label: `${member.forename} ${member.surname}`,
    key: `${member.member_id}`,
    value: `${member.forename} ${member.surname}`,
  }));
  //console.log('formatted members =' + JSON.stringify(formattedMembers))

  // List of data to populate select fields, formatted as needed by custom MUI select component
  const formattedStaff = staff.map((staff) => ({
    label: `${staff.full_name}`,
    key: `${staff.staff_id}`,
    value: `${staff.full_name}`,
  }));
  //console.log('formatted staff =' + JSON.stringify(formattedStaff))

  // List of data to populate select fields, formatted as needed by custom MUI select component
  const formattedEvents = event.map((event) => ({
    label: `${event.event_name}`,
    key: `${event.event_id}`,
    value: `${event.event_name}`,
  }));
  //console.log('formatted event =' + JSON.stringify(formattedEvents))

  return (
    <div className="createMemberEventPage px-5 relative">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, formik) => {
          console.log('raw vales =' + JSON.stringify(values))

          // Deconstructed data from form, in format needed for member_events table
          const memberEventOutput = [];
          values.member_id.forEach((member_id) => {
            memberEventOutput.push({
              member_id,
              event_id: values.event_id,
              event_date: values.event_date,
              last_updated_by: values.last_updated_by,
            });
          });
          console.log('memberEventOutput =' + JSON.stringify(memberEventOutput))

          // Deconstructed data from form, in format needed for staff_events table
          const staffEventOutput = [];
          values.staff_id.forEach((staff_id) => {
            staffEventOutput.push({
              staff_id,
              event_id: values.event_id,
              event_date: values.event_date,
              last_updated_by: values.last_updated_by,
            });
          });
          console.log('staffEventOutput =' + JSON.stringify(staffEventOutput))

          // Below is the function to send the data to react-query and to control the snackbar success or error messages
          memberEventMutate(memberEventOutput, {
            onSuccess: () => {
              setOpen(true);
              setMessage('New member-event created!');
              setSeverity('success');
              setButtonIsClicked(buttonIsClicked + 1); //updates Recoil global state, to trigger data-table refetch of data
              formik.resetForm();
            },
            onError: (response) => {
              setOpen(true);
              setMessage('Error, new member-event not created!');
              setSeverity('error');
              console.log(response);
            },
          });

          staffEventMutate(staffEventOutput, {
            onSuccess: () => {
              setOpen(true);
              setMessage('New member-event created!');
              setSeverity('success');
              setButtonIsClicked(buttonIsClicked + 1); //updates Recoil global state, to trigger data-table refetch of data
              formik.resetForm();
            },
            onError: (response) => {
              setOpen(true);
              setMessage('Error, new member-event not created!');
              setSeverity('error');
              console.log(response);
            },
          });
        }}
      >
        <Form className="formContainer">
          <h1 className="pb-3 text-xl font-semibold">General Information</h1>

          <div>
            <CustomDateField name="event_date" label="Date Of Event" />
          </div>

          <div>
            <CustomSelectFieldSaveById
              name="event_id"
              label="Event Name"
              options={formattedEvents}
            />
          </div>

          <div>
            <CustomMultipleSelectField
              name="member_id"
              label="Members"
              options={formattedMembers}
            />
          </div>

          <div>
            <CustomMultipleSelectField
              name="staff_id"
              label="Staff and Volunteers"
              options={formattedStaff}
            />
          </div>

          <div className="pb-2 relative">
            <Field
              type="hidden"
              autoComplete="off"
              id="inputCreateMember"
              name="last_updated_by"
              placeholder=" "
            />
          </div>

          <div className="flex flex-col items-center pt-7">
            <Button variant="contained" size="large" type="submit">
              Create Member Event
            </Button>
          </div>
          <div className="pb-20">
            <Snackbar
              open={open}
              autoHideDuration={5000}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              sx={{ position: 'absolute' }}
            >
              <Alert
                severity={severity}
                variant="filled"
                sx={{ width: '100%' }}
              >
                {message}
              </Alert>
            </Snackbar>
          </div>
        </Form>
      </Formik>
    </div>
  );
}

export default MemberEventsCreate;
