import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup'; //yup does form validation
import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  membersCommentsButtonClickedState,
  userNameState,
} from '../Atoms/atoms';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import CustomTextField from '../CustomComponents/CustomTextField';

//Variable to store Tailwind css for 'Field' elements of Formik function
const formikField =
  'whitespace-pre-line my-px block px-2.5 pb-2.5 pt-4 w-full text-base text-gray-900 bg-transparent rounded-lg border border-gray-400 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer focus:border-2';

//Variable to store Tailwind css for 'Label' elements of Formik function
const formikLabel =
  'absolute text-base text-gray-500 duration-300 transform -translate-y-5 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-6 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-4 left-2';


//react-query useMutation code
const useMembersCommentsCreateMutation = () => {
  return useMutation(async (formPayload) => {
    return await axios.post(
      process.env.REACT_APP_BE_URL + '/memberscomments',
      formPayload
    );
  });
};

//Main function/custom useMutation Hook - creates Formik form
function MembersCommentsEdit(props) {
  const { mutate } = useMembersCommentsCreateMutation();
  //console.log('props from MembersCommentsEdit in commentsedit.jsx= ' + JSON.stringify(props))

  //Gets logged in username from auth.js
  const userName = useRecoilValue(userNameState);
  //console.log('userName from members edit form= ' + JSON.stringify(userName))

  //Destructuring comments array for populating 'existing comments' field
  const memberCommentsArray = [
    props.membersCommentsData.map(
      (separatedComments) => separatedComments.member_comment
    ),
  ];
  //console.log('memberCommentsArray', memberCommentsArray)

  const createdAtArray = [
    props.membersCommentsData.map(
      (separatedComments) => separatedComments.createdAt
    ),
  ];

  const createdByArray = [
    props.membersCommentsData.map(
      (separatedComments) => separatedComments.last_updated_by
    ),
  ];

  const finalArray = [];

  //Concatenates each required destructured element of the original array
  createdAtArray[0].forEach((i, index) => {
    finalArray.push([
      i,
      createdByArray[0][index],
      memberCommentsArray[0][index],
    ]);
  });
  //console.log('finalArray', finalArray);

  // inserts line spacing between array items
  const joinedArray = finalArray.join('\n \n');

  //Formik initial values
  const initialValues = {
    forename: props?.membersCommentsData?.[0].forename ?? '',
    surname: props?.membersCommentsData?.[0].surname ?? '',
    address_1: props?.membersCommentsData?.[0].address_1 ?? '',
    last_updated_by: userName.user.displayName,
    member_id: props?.membersCommentsData?.[0].member_id ?? '',
    member_comment: '',
    existing_member_comments: joinedArray,
  };

  // Yup field validation
  const validationSchema = Yup.object().shape({
    member_comment: Yup.string().required('*A comment is required'),
  });

  // Recoil global state to trigger data table refresh after member edit button is clicked
  const [buttonisClicked, setButtonIsClicked] = useRecoilState(
    membersCommentsButtonClickedState
  );

  // State for MUI snackbar popup open status
  const [open, setOpen] = useState(false);

  //State for MUI snackbar popup message
  const [message, setMessage] = useState('');

  //State for MUI snackbar popup severity
  const [severity, setSeverity] = useState();

  // Variable for property to close MUI snackbar popup
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  //Formats today's date
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = today.getFullYear();
  today = yyyy + '-' + mm + '-' + dd;

  return (
    <>
      <div className="createMemberCommentPage px-5 relative">
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, formik) => {
            //console.log('values=', values);
            mutate(values, {
              onSuccess: () => {
                setOpen(true);
                setMessage('Member comment saved!');
                setSeverity('success');
                setButtonIsClicked(buttonisClicked + 1); //updates Recoil global state, to trigger data-table refetch of data

                formik.setFieldValue(
                  'existing_member_comments',
                  'Created On: ' +
                    today +
                    ',\n' +
                    'Added by: ' +
                    values.last_updated_by +
                    ',\n' +
                    'Comment: ' +
                    values.member_comment +
                    '\n \n' +
                    values.existing_member_comments
                );

                formik.setFieldValue('member_comment', '', false);
              },
              onError: (response) => {
                setOpen(true);
                setMessage('Error, member comment not saved!');
                setSeverity('error');
                console.log(response);
              },
            });
          }}
        >
          <Form className="formContainer">
            <div>
              <CustomTextField
                name="forename"
                label="Forename"
                readOnly={true}
              />
            </div>

            <div>
              <CustomTextField name="surname" label="Surname" readOnly={true} />
            </div>

            <div>
              <CustomTextField
                name="address_1"
                label="Address Line 1"
                readOnly={true}
              />
            </div>

            <div className="pb-2 relative">
              <Field
                className={formikField}
                autoComplete="off"
                id="inputCreateMemberComment"
                name="existing_member_comments"
                placeholder=" "
                component="textarea"
                rows="10"
                readOnly={true}
              />
              <label className={formikLabel}>Existing Member Comments</label>
            </div>

            <div>
              <CustomTextField
                name="member_comment"
                label="Add Another Member Comment Here"
              />
            </div>

            <div>
              <Field
                type="hidden"
                autoComplete="off"
                id="inputCreateMemberComment"
                name="last_updated_by"
                placeholder=" "
              />
            </div>

            <div>
              <Field
                type="hidden"
                autoComplete="off"
                id="inputCreateMemberComment"
                name="member_id"
                placeholder=" "
              />
            </div>

            <div className="flex flex-col items-center pt-4">
              <Button variant="contained" size="large" type="submit">
                Save Changes
              </Button>
            </div>

            <div className="pb-20">
              <Snackbar
                open={open}
                autoHideDuration={5000}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                sx={{ position: 'absolute' }}
              >
                <Alert
                  severity={severity}
                  variant="filled"
                  sx={{ width: '100%' }}
                >
                  {message}
                </Alert>
              </Snackbar>
            </div>
          </Form>
        </Formik>
      </div>
    </>
  );
}

export default MembersCommentsEdit;
