import React from 'react'
import { useEffect, useState } from 'react';
import DataTable from '../DataTable/DataTable';
import axios from 'axios'
import Button from '@mui/material/Button'
import EditIcon from '@mui/icons-material/Edit'
import { useRecoilValue } from 'recoil'
import { memberButtonClickedState } from '../Atoms/atoms'
 
  const columns = [

    { 
      field: 'member_id', 
      hide:true 
      },
    
    {field: 'Edit', 
      headername:'Edit', 
      width:100, 
      sortable: false, 
      filterable: false, 
      disableClickEventBubbling: true,
      renderCell: () => {
        return (
          <Button variant="contained" color="primary" startIcon={<EditIcon />}></Button>
        )}
    },
    { field: 'member_type', headerName: 'Member Type', width: 300 },
    { field: 'forename', headerName: 'Forename', width: 150 },
    { field: 'surname', headerName: 'Surname', width: 150 },
    { field: 'known_as', headerName: 'Known as', width: 150 },
    { field: 'date_of_birth', headerName: 'Date Of Birth', width: 150 },
    { field: 'gender', headerName: 'Gender', width: 150 },
    { field: 'ethnicity', headerName: 'Ethnicity', width: 150 },
    { field: 'address_1', headerName: 'Address 1', width: 150 },
    { field: 'address_2', headerName: 'Address 2', width: 150 },
    { field: 'address_3', headerName: 'Address 3', width: 150 },
    { field: 'town', headerName: 'Town', width: 150 },
    { field: 'postcode', headerName: 'Postcode', width: 150 },
    { field: 'directions', headerName: 'Directions', width: 150 },
    { field: 'phone_number', headerName: 'Phone', width: 150 },
    { field: 'email_address', headerName: 'Email', width: 150 },
    { field: 'next_of_kin_name', headerName: 'Next of Kin Name', width: 150 },
    { field: 'next_of_kin_relationship', headerName: 'Next of Kin Relationship', width: 150 },
    { field: 'next_of_kin_phone', headerName: 'Next of Kin Phone', width: 150 },
    { field: 'next_of_kin_local', headerName: 'Next of Kin Local', width: 150 },
    { field: 'emergency_contact_2_name', headerName: 'Contact 2 Name', width: 150 },
    { field: 'emergency_contact_2_relationship', headerName: 'Contact 2 Relationship', width: 150 },
    { field: 'emergency_contact_2_phone', headerName: 'Contact 2 Phone', width: 150 },
    { field: 'doctor_name', headerName: 'Doctor Name', width: 150 },
    { field: 'surgery_name', headerName: 'Surgery Name', width: 150 },
    { field: 'doctor_phone', headerName: 'Doctor Phone', width: 150 },
    { field: 'impaired_hearing', headerName: 'Impaired Hearing', width: 150 },
    { field: 'impaired_vision', headerName: 'Impaired Vision', width: 150 },
    { field: 'impaired_mobility', headerName: 'Impaired Mobility', width: 150 },
    { field: 'fall_risk', headerName: 'Fall Risk', width: 150 },
    { field: 'dementia', headerName: 'Dementia', width: 150 },
    { field: 'impaired_communication', headerName: 'Impaired impaired_communication', width: 150 },
    { field: 'wander_risk', headerName: 'Wander Risk', width: 150 },
    { field: 'diabetes', headerName: 'Diabetes', width: 150 },
    { field: 'food_allergies', headerName: 'Food Allergies', width: 150 },
    { field: 'catheter', headerName: 'Catheter', width: 150 },
    { field: 'medical_equipment', headerName: 'Medical Equipment', width: 150 },
    { field: 'other_medical_issues', headerName: 'Other Med Issues', width: 150 },
    { field: 'interests', headerName: 'Interests', width: 150 },
    { field: 'topics_to_avoid', headerName: 'Avoid Topics', width: 150 },
    { field: 'blue_badge_holder', headerName: 'Blue Badge Holder', width: 150 },
    { field: 'other_info', headerName: 'Other Info', width: 150 },
    { field: 'is_deceased', headerName: 'Deceased', width: 150 },
    { field: 'cared_for_medical_conditions', headerName: 'Cared-for Med Issues', width: 150 },
    { field: 'gdpr_form_signed', headerName: 'GDPR Form Signed', width: 150 },
    { field: 'gdpr_form_link', headerName: 'GDPR Form Link', width: 150 },
  ];

const MembersTable = () => {

    const[members, setMembers] = useState([])
    // console.log(members)

    const flag = useRecoilValue(memberButtonClickedState)
    // console.log(flag)

      useEffect(() => {
        axios.get(process.env.REACT_APP_BE_URL+'/members')
            .then((res) => res.data)
            .then(data => setMembers(data))
    }, [flag])
    return (
          <><div>
        <DataTable
          rows={members}
          columns={columns}
          loading={!members.length}
          // sx={membersTableStyles} 
          sx={{height: '424px', '& .MuiDataGrid-columnHeaderTitle': {fontWeight: 700,} }}/>
      </div></>
    )
}

export default MembersTable