import { atom } from 'recoil'

export const memberButtonClickedState = atom({
    key: 'memberButtonClickedState',
    default: 0
})

export const eventButtonClickedState = atom({
    key: 'eventButtonClickedState',
    default: 0
})

export const staffButtonClickedState = atom({
    key: 'staffButtonClickedState',
    default: 0
})

export const memberEventButtonClickedState = atom({
    key: 'memberEventButtonClickedState',
    default: 0
})

export const userNameState = atom({
    key: 'userNameState',
    default: 0
})

export const userSettingsButtonClickedState = atom({
    key: 'userSettingsButtonClickedState',
    default: 0
})

export const servicesButtonClickedState = atom({
    key: 'servicesButtonClickedState',
    default: 0
})

export const membersCommentsButtonClickedState = atom({
    key: 'membersCommentsButtonClickedState',
    default: 0
})

export const membersBirthdaysButtonClickedState = atom({
    key: 'membersBirthdaysButtonClickedState',
    default: 0
})

export const memberServicesButtonClickedState = atom({
    key: 'memberServicesButtonClickedState',
    default: 0
})

export const memberIdForCommentsState = atom({
    key: 'memberIdForCommentsState',
    default: '1'  //default set to '1' to prevent errors in routes
})