import { useEffect, useRef, memo } from 'react';
import Button from '@mui/material/Button';
import { useFormikContext } from 'formik';

const UploadWidget = () => {
  const cloudinaryRef = useRef();
  const widgetRef = useRef();
  const { setFieldValue, values } = useFormikContext();

  useEffect(() => {
    cloudinaryRef.current = window.cloudinary;
    widgetRef.current = cloudinaryRef.current.createUploadWidget(
      {
        cloudName: process.env.REACT_APP_CLOUDINARY_CLOUDNAME,
        uploadPreset: process.env.REACT_APP_CLOUDINARY_UPLOADPRESET,
        sources: ['local', 'camera'],
        cropping: true,
        multiple: false,
        resourceType: 'image',
      },
      function (error, result) {
        if (result && result.event === 'success') {
          setFieldValue('gdpr_form_link', result.info.secure_url);
        }
      }
    );
  }, [setFieldValue]);

  const handleButtonClick = () => {
    if (!values.gdpr_form_link.includes('http:')) {
      widgetRef.current.open();
    }
  };

  //Checks whether the GDPR form link field already contains a link to Cloudinary (so existing file is not overwritten)
  const isButtonDisabled = values.gdpr_form_link.includes('https:');

  return (
    <div className="flex flex-col items-center width-full pb-4">
      <Button
        variant="contained"
        size="large"
        onClick={handleButtonClick}
        fullWidth={true}
        disabled={isButtonDisabled}
      >
        Upload Signed GDPR Form
      </Button>
    </div>
  );
};

export default memo(UploadWidget);
