import React, { useState } from 'react';
import { FastField } from 'formik';
import { TextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';

const CustomCurrencyField = ({ name, label, rows, readOnly, startAdornment, ...props }) => {

  //Focus functionality here and below is to hide the currency symbol until the field is selected
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <div className="pb-4">
      <FastField name={name}>
        {({ field, meta }) => (
          <TextField
            {...field}
            {...props}
            label={label}
            rows={rows}
            error={Boolean(meta.touched && meta.error)}
            helperText={meta.touched && meta.error}
            sx={{
              width: '100%',
            }}
            variant="outlined"
            onFocus={handleFocus}
            onBlur={handleBlur}
            InputProps={{
              startAdornment: isFocused ? (
                <InputAdornment position="start">£</InputAdornment>
              ) : null,
            }}
          />
        )}
      </FastField>
    </div>
  );
};

export default React.memo(CustomCurrencyField);
