import React, { useEffect, useState } from 'react'
import axios from 'axios'
import ServicesEdit from '../Services/ServicesEdit'


function ServicesByID(props) {
  
  const [servicesData, setServicesData] = useState([])
  
  
    useEffect(() => {
      axios.get(process.env.REACT_APP_BE_URL+`/services/byId/${props.id}`)
      .then((response) => {
        setServicesData(response.data);
      })
    }, [props.id])
    // console.log(eventData)

    //Conditional rendering - only displays the edit form if a row has been clicked in the table.  
    //Prevents user filling in the form without clicking a row, which would crash node due to lack of event_id 
    if (props.id === undefined) 
     {return}
    else {  
      return (
          //next line takes props from DataTable.jsx - where the prop = id, which in turn equals the row id clicked in the table
          <>
          <ServicesEdit ServicesData={servicesData}/></>
      )}
}

export default ServicesByID
