import React from 'react';
import { useEffect, useState } from 'react';
import DataTableMemberServices from '../DataTable/DataTableMemberServices';
import axios from 'axios';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import { useRecoilValue } from 'recoil';
import { memberServicesButtonClickedState } from '../Atoms/atoms';

const columns = [
  {
    field: 'member_service_id',
    hide: true,
  },

  {
    field: 'Edit',
    headername: 'Edit',
    width: 100,
    sortable: false,
    filterable: false,
    disableClickEventBubbling: true,
    renderCell: () => {
      return (
        <Button
          variant="contained"
          color="primary"
          startIcon={<EditIcon />}
        ></Button>
      );
    },
  },

  { field: 'service_name', headerName: 'Service Name', width: 170 },
  { field: 'service_date', headerName: 'Service Date', width: 150 },
  { field: 'staff_full_name', headerName: 'Driver Name', width: 170 },
  { field: 'member_full_name', headerName: 'Member Name', width: 190 },
  { field: 'date_created', headerName: 'Date Created', width: 150 },
  { field: 'request_cancelled', headerName: 'Request Cancelled', width: 150 },
];

const MemberServicesTable = () => {
  const [memberServices, setMemberServices] = useState([]);
  //console.log('memberservices = ' + JSON.stringify(memberServices));

  const flag = useRecoilValue(memberServicesButtonClickedState);

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BE_URL + '/memberservices')
      .then((res) => res.data)
      .then((data) => setMemberServices(data));
  }, [flag]);

  return (
    <>
      <div>
        <DataTableMemberServices
          rows={memberServices}
          columns={columns}
          loading={!memberServices.length}
          sx={{
            height: '424px',
            '& .MuiDataGrid-columnHeaderTitle': { fontWeight: 700 },
          }}
        />
      </div>
    </>
  );
};

export default MemberServicesTable;
