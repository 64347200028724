import React, { useState } from 'react';
import { DataGrid, GridToolbarQuickFilter } from '@mui/x-data-grid';

//Function that adds the Search box above the data grid
function QuickSearchToolbar() {
  return <GridToolbarQuickFilter />;
}

const DataTableNotificationsComments = ({
  rows,
  columns,
  loading,
  sx,
  getRowHeight,
}) => {
  //State to manage pagination - default 20 rows per screen
  const [pageSize, setPageSize] = useState(20);

  return (
    <>
      <div className="w-full">
        <DataGrid
          getRowHeight={getRowHeight}
          rows={rows}
          columns={columns}
          loading={loading}
          getRowId={(row) => row.member_comment_id}
          sx={sx}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[5, 10, 20, 100]}
          components={{ Toolbar: QuickSearchToolbar }} // activates the searchbar above the data grid
          initialState={{
            sorting: {
              sortModel: [{ field: 'createdAt', sort: 'desc' }],
            },
          }}
        />
      </div>
    </>
  );
};

export default DataTableNotificationsComments;
