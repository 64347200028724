// import React, { useEffect, useState } from 'react'
// import axios from 'axios';

export default function LogoutSuccess() {

  return (
    <div className="flex flex-col h-screen items-center w-full pt-80">
      <h1 className="text-3xl font-bold">Logout Successful!</h1>
    </div>
  );
}
